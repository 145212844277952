<template>
  <div class="container">
        <div class="panel panel-info">
            <div class="panel-body">
                <h3 class="panel-title">
                    {{$t('Step', {lng: this.$store.getters.language}) }} 1 {{$t('StepDescription1', {lng: this.$store.getters.language}) }}
                </h3>
              <fees-table></fees-table>
            </div>
        </div>
        <div class="panel panel-info">
            <div class="panel-body">
                <form>
                    <div class="form-group">
                        <label for="moveInDate">{{$t('Step1MoveInDate', {lng: this.$store.getters.language}) }}</label>
                        <datepicker v-model="moveindate" id="moveindate" placeholder="mm-dd-yyyy" :config="{ dateFormat: 'm-d-Y', static: true }"
                                    data-vv-name="moveindate" v-validate="'required'"
                        ></datepicker>
                      <span style="color: darkred" v-show="errors.has('moveindate')">required</span>
                    </div>
                    <div class="form-group">
                        <label for="desired">{{$t('Step1DesiredApartment', {lng: this.$store.getters.language}) }}</label>
                        <textarea id="desired"
                                  rows="2"
                                  v-model="desiredapartment"
                                class="form-control"
                                  data-vv-name="desired" v-validate="'required'"
                        />
                      <span style="color: darkred" v-show="errors.has('desired')">required</span>
                    </div>
                    <div class="form-group">
                        <label>{{$t('Step1NumberOccupants', {lng: this.$store.getters.language}) }}</label>
                        <input @change="changeOccupants"
                                type="number"
                                class="form-control"
                                v-model="numberoccupants"
                               min="0" max="5" default=""
                        >
                    </div>
                    <div>
                        <app-occupants></app-occupants>
                    </div>
                    <div class="form-group col-xs-12">
                        <label for="comments" class="d-inline">{{$t('Step1AdditionalComments', {lng: this.$store.getters.language}) }}</label>
                        <div class="d-inline">
                            <textarea name="comments" id="comments"
                                      rows="4"
                                      class="form-control"
                                      v-model="comments"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="panel-footer">
            <button
                    class="btn btn-success"
                    @click="Next"
            >{{$t('next', {lng: this.$store.getters.language}) }}
            </button>
        </div>
        <footer>RA</footer>
    </div>
</template>

<style scoped>
    .danger {
        border: 1px solid red;
    }
</style>

<script>
  import {mapActions} from 'vuex'
  import Datepicker from 'vue-bulma-datepicker'
  import Occupants from './Occupants.vue'
  import FeesTable from './FeesTable.vue'

  export default {
    props: ['step1'],
    components: {
      FeesTable,
      Datepicker,
      appOccupants: Occupants
    },
    data () {
      return {
        isDropdownOpen: false,
        language: 'en'
      }
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      if (this.$route.query.language) {
        this.language = this.$store.getters.language
      } else {
        this.language = 'en'
      }
      this.$store.dispatch('setNumberoccupants', 0)
    },
    computed: {
      occupants () {
        return this.$store.getters.occupants
      },
      rentalfee () {
        if (this.$store.getters.dorental) {
          if (this.$store.getters.docoapp === 'true') {
            return this.$store.getters.rentalreportprice * 2
          } else {
            return this.$store.getters.rentalreportprice
          }
        } else {
          return 0.00
        }
      },
      evictionfee () {
        if (this.$store.getters.doevictions === 'true') {
          if (this.$store.getters.docoapp === 'true') {
            return this.$store.getters.evictionprice * 2
          } else {
            return this.$store.getters.evictionprice
          }
        } else {
          return 0.00
        }
      },
      employmentfee () {
        if (this.$store.getters.doemployment === 'true') {
          if (this.$store.getters.docoapp === 'true') {
            return this.$store.getters.employmentreportprice * 2
          } else {
            return this.$store.getters.employmentreportprice
          }
        } else {
          return 0.00
        }
      },
      applicantfee () {
        return this.$store.getters.raapplicationfee
      },
      adultapplicationfee () {
        return this.$store.getters.adultapplicationfee
      },
      comments: {
        get () {
          return this.$store.getters.comments
        },
        set (value) {
          this.$store.dispatch('setComments', value)
        }
      },
      moveindate: {
        get () {
          return this.$store.getters.moveindate
        },
        set (value) {
          this.$store.dispatch('setMoveInDate', value)
        }
      },
      desiredapartment: {
        get () {
          return this.$store.getters.desiredapartment
        },
        set (value) {
          this.$store.dispatch('setDesiredapartment', value)
        }
      },
      numberoccupants: {
        get () {
          return this.$store.getters.numberoccupants
        },
        set (value) {
          this.$store.dispatch('setNumberoccupants', value)
        }
      }

    },
    methods: {
      ...mapActions({
        placeSellOrder: 'sellStock'
      }),
      formatPrice (value) {
        let val = (value / 1).toFixed(2).replace('.', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      changeOccupants () {
        const occ = []
        for (var x = 1; x <= this.numberoccupants; x++) {
          occ.push({id: x, name: '', relationship: '', age: ''})
        }
        this.$store.dispatch('setOccupants', occ)
      },
      Next () {
        // todo validate
        this.$validator.validateAll().then(() => {
          if (this.errors.items.length) {
            console.log('this.errors.items.length')
            console.log(this.errors.items.length)
          } else {
            console.log('no errors')
            window.scrollTo(0, 0)
            this.$router.push({name: 'Step2'})
          }
        })
      }
    }
  }
</script>

