<template>
  <div class="col-sm-12 col-md-12">
    <div class="panel panel-info">
      <div class="panel-heading">
        <h3 class="panel-title">
          {{ additionalincome.who }}'s {{additionalincomeheader}}: {{ additionalincome.id }}
        </h3>
      </div>
      <div class="panel-body">
        <div class="form-grouppull-left">
          <input
            type="hidden"
            class="form-control"
            :placeholder="id"
            v-model="id"
            :class="{danger: emptyName}"
          >
          <div>
            <label for="incomesource"> {{incomesourceplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="incomesourceplaceholder"
            v-model="incomesource"
            id="incomesource"
            @input="updateIncomesource"
            data-vv-name="incomesource"
            >
            <span style="color: darkred" v-show="errors.has('incomesource')">required</span>
          </div>
          <div>
            <label for="amount"> {{amountplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="amountplaceholder"
            v-model="amount"
            id="amount"
            @input="updateAmount"
            data-vv-name="amount"
            >
            <span style="color: darkred" v-show="errors.has('amount')">required</span>
          </div>
          <div>
            <label for="verifywith"> {{verifywithplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="verifywithplaceholder"
            v-model="verifywith"
            id="verifywith"
            @input="updateVerifywith"
            data-vv-name="verifywith"
            >
            <span style="color: darkred" v-show="errors.has('verifywith')">required</span>
          </div>
          <div>
            <label for="telephone"> {{telephoneplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="telephoneplaceholder"
            v-model="telephone"
            id="telephone"
            @input="updateTelephone"
            data-vv-name="telephone"
            v-mask="'(###)-###-####'"
            >
            <span style="color: darkred" v-show="errors.has('telephone')">required</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .danger {
    border: 1px solid red;
  }
</style>

<script>

  export default {
    props: ['additionalincome'],
    inject: ['$validator'],
    data () {
      return {
        id: this.additionalincome.id,
        incomesource: this.additionalincome.who === 'Applicant' ? this.$store.getters.step4additionalincomeincomesource : this.$store.getters.step4additionalincomeincomesource2,
        amount: this.additionalincome.who === 'Applicant' ? this.$store.getters.step4additionalincomeamount : this.$store.getters.step4additionalincomeamount2,
        telephone: this.additionalincome.who === 'Applicant' ? this.$store.getters.step4additionalincometelephone : this.$store.getters.step4additionalincometelephone2,
        verifywith: this.additionalincome.who === 'Applicant' ? this.$store.getters.step4additionalincomeverifywith : this.$store.getters.step4additionalincomeverifywith2,
        additionalincomeheader: this.$i18n.t('step4additionalincome'),
        incomesourceplaceholder: this.$i18n.t('step4incomesource'),
        amountplaceholder: this.$i18n.t('step4amount'),
        verifywithplaceholder: this.$i18n.t('step4verifywith'),
        telephoneplaceholder: this.$i18n.t('step4telephone')
      }
    },
    computed: {
      emptyName () {
        return this.name === ''
      }
    },
    methods: {
      updateIncomesource (event) {
        const occup = event.target.value
        if (this.additionalincome.who === 'Applicant') {
          this.$store.dispatch('updateStep4additionalincomeincomesource', occup)
        } else {
          this.$store.dispatch('updateStep4additionalincomeincomesource2', occup)
        }
      },
      updateAmount (event) {
        const occup = event.target.value
        if (this.additionalincome.who === 'Applicant') {
          this.$store.dispatch('updateStep4additionalincomeamount', occup)
        } else {
          this.$store.dispatch('updateStep4additionalincomeamount2', occup)
        }
      },
      updateTelephone (event) {
        const occup = event.target.value
        if (this.additionalincome.who === 'Applicant') {
          this.$store.dispatch('updateStep4additionalincometelephone', occup)
        } else {
          this.$store.dispatch('updateStep4additionalincometelephone2', occup)
        }
      },
      updateVerifywith (event) {
        const occup = event.target.value
        if (this.additionalincome.who === 'Applicant') {
          this.$store.dispatch('updateStep4additionalincomeverifywith', occup)
        } else {
          this.$store.dispatch('updateStep4additionalincomeverifywith2', occup)
        }
      }
    }
  }
</script>
