<template>
  <div class="container">
    <div class="container-fluid">

      <div class="panel panel-info">
        <div class="panel-body">
          {{$t('Step', {lng: this.$store.getters.language}) }} 4 {{$t('step4title', {lng: this.$store.getters.language})
          }}
        </div>
      </div>
      <div>
        <app-current-employment :employment=whichapplicant :who="Applicant"></app-current-employment>
      </div>
      <div>
        <app-additional-income :additionalincome=whichapplicant :who="Applicant"></app-additional-income>
      </div>

      <div v-if="displayCo != 'false'">
        <div>
          <current-employment-co-applicant :employment="{who: 'Co-Applicant'}"
                                           :who="Co-Applicant"></current-employment-co-applicant>
        </div>
        <div>
          <additional-income-co-applicant :additionalincome="{who: 'Co-Applicant'}"
                                          :who="Co-Applicant"></additional-income-co-applicant>
        </div>
      </div>

    </div>
    <div class="panel-footer">
      <button class="btn btn-success" type="submit" @click="Previous">
        {{$t('previous', {lng: this.$store.getters.language}) }}
      </button>
      <button class="btn btn-success" type="submit" @click="Next">{{$t('next', {lng: this.$store.getters.language}) }}
      </button>
    </div>
  </div>
</template>

<script>

  import CurrentEmployment from './CurrentEmployment.vue'
  import CurrentEmploymentCoApplicant from './CurrentEmploymentCoApplicant.vue'
  import AdditionalIncome from './AdditionalIncome.vue'
  import AdditionalIncomeCoApplicant from './AdditionalIncomeCoApplicant.vue'

  export default {
    data () {
      return {
        displayCo: this.$store.getters.docoapp,
        isDropdownOpen: false,
        employment: {},
        Applicant: ''
      }
    },
    components: {
      appCurrentEmployment: CurrentEmployment,
      CurrentEmploymentCoApplicant,
      appAdditionalIncome: AdditionalIncome,
      AdditionalIncomeCoApplicant
    },
    watch: {
      '$route' (to) {
        this.language = to.query.language
      }
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.$i18n.i18next.changeLanguage(this.$store.getters.language)
      if (this.$route.query.language) {
        this.language = this.$route.query.language
      } else {
        this.language = 'en'
      }
    },
    computed: {
      whichapplicant: {
        get () {
          this.$i18n.i18next.changeLanguage(this.$store.getters.language)
          let apl = this.$i18n.t('applicant')
          return {who: apl}
        }
      },
      whichcoapplicant: {
        get () {
          this.$i18n.i18next.changeLanguage(this.$store.getters.language)
          let apl = this.$i18n.t('coapplicant')
          return {who: apl, coapplicant: 'true'}
        }
      }
    },
    methods: {
      Previous () {
        window.scrollTo(0, 0)
        this.$router.push({name: 'Step3'})
      },
      Next () {
        this.$validator.validateAll().then(() => {
          if (this.errors.items.length) {
            console.log('this.errors.items.length')
            console.log(this.errors.items)
          } else {
            console.log('no errors')
            window.scrollTo(0, 0)
            this.$router.push({name: 'Step5'})
          }
        })
      }
    }
  }
</script>
