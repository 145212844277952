<template>
  <div class="col-sm-12 col-md-12">
    <div class="panel panel-info">
      <div class="panel-heading">
        <h3 class="panel-title">
          {{ address.who }}'s {{$t('step3currentaddress', {lng: this.$store.getters.language}) }}: {{ address.id }}
        </h3>
      </div>
      <div class="panel-body">
        <div class="form-grouppull-left">
          <input
            type="hidden"
            class="form-control"
            placeholder="id"
            v-model="id"
            :class="{danger: emptyName}"
          >
          <div class="checkbox" v-if="address.coapplicant === 'true'">
            <label>
              <input type="checkbox"> {{$t('step3currentaddresscheckbox') }}
            </label>
          </div>
          <label for="apartmentname"> {{currentapartmentnameplaceholder}}</label>
          <input
            class="form-control"
            :placeholder="currentapartmentnameplaceholder"
            v-model="apartmentname"
            @input="updateApartmentName"
            :class="{danger: emptyName}"
            name="apartmentname"
            id="apartmentname"
            data-vv-name="apartmentname" v-validate="'required'"
          >
          <span style="color: darkred" v-show="errors.has('apartmentname')">required</span>
          <label for="managersname"> {{managersnameplaceholder}}</label>
          <input
            class="form-control"
            :placeholder="managersnameplaceholder"
            v-model="managersname"
            name="managersname"
            id="managersname"
            @input="updateManagersname"
            data-vv-name="managersname" v-validate="'required'"
          >
          <span style="color: darkred" v-show="errors.has('managersname')">required</span>
          <label for="telephone"> {{telephoneplaceholder}}</label>
          <input
            class="form-control"
            :placeholder="telephoneplaceholder"
            v-model="telephone"
            id="telephone"
            @input="updateTelephone"
            data-vv-name="telephone" v-validate="'required'"
            v-mask="'(###)-###-####'"
          >
          <span style="color: darkred" v-show="errors.has('telephone')">required</span>
          <label for="address1"> {{address1placeholder}}</label>
          <input
            class="form-control"
            :placeholder="address1placeholder"
            v-model="address1"
            id="address1"
            @input="updateAddress"
            data-vv-name="address1" v-validate="'required'"
          >
          <span style="color: darkred" v-show="errors.has('address1')">required</span>
          <label for="city"> {{cityplaceholder}}</label>
          <input
            class="form-control"
            :placeholder="cityplaceholder"
            v-model="city"
            id="city"
            @input="updateCity"
            data-vv-name="city" v-validate="'required'"
          >
          <span style="color: darkred" v-show="errors.has('city')">required</span>
          <label for="state"> {{stateplaceholder}}</label>
          <b-form-select id="state" v-model="state" @change="updateState"
                         data-vv-name="state" v-validate="'required'"
          >
            <option v-for="(item, index) in states" @value="index" :key="index">{{item}}</option>
          </b-form-select>
          <span style="color: darkred" v-show="errors.has('state')">required</span>
          <label for="zip"> {{zipplaceholder}}</label>
          <input
            class="form-control"
            :placeholder="zipplaceholder"
            v-model="zip"
            id="zip"
            @input="updateZip"
            v-mask="'#####'"
            data-vv-name="zip" v-validate="'required'"
          >
          <span style="color: darkred" v-show="errors.has('zip')">required</span>
          <label for="rentamount"> {{rentmortageamountplaceholder}}</label>
          <input
            class="form-control"
            :placeholder="rentmortageamountplaceholder"
            v-model="rentamount"
            id="rentamount"
            @input="updateRentAmount"
            data-vv-name="rentamount" v-validate="'required'"
          >
          <span style="color: darkred" v-show="errors.has('rentamount')">required</span>
          <label for="moveindate"> {{moveindateplaceholder}}</label>
          <b-row>
            <b-col>Month</b-col>
            <b-col>Year</b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-select id="moveindate" v-model="moveindate"
                             data-vv-name="moveindate" v-validate="'required'"
                             @input="updateMoveInDate"
              >
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </b-form-select>
            </b-col>
            <b-col>
              <b-form-select id="moveindateyear" v-model="moveindateyear"
                             data-vv-name="moveindateyear" v-validate="'required'"
                             @input="updateMoveInDateYear"
              >
                <option value="0">Year:</option>
                <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
              </b-form-select>
            </b-col>
          </b-row>
          <span style="color: darkred" v-show="errors.has('moveindate')">required</span>
          <label for="reasonforleaving"> {{reasonforleavingplaceholder}}</label>
          <input
            class="form-control"
            :placeholder="reasonforleavingplaceholder"
            v-model="reasonforleaving"
            id="reasonforleaving"
            @input="updateReasonForLeaving"
            data-vv-name="reasonforleaving" v-validate="'required'"
          >
          <span style="color: darkred" v-show="errors.has('reasonforleaving')">required</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .danger {
    border: 1px solid red;
  }
</style>
<script>
  export default {
    props: ['address', 'who'],
    inject: ['$validator'],
    data () {
      return {
        id: this.address.id,
        apartmentname: this.address.who === 'Applicant' ? this.$store.getters.step3currentaddresscurrentapartmentname : this.$store.getters.step3currentaddresscurrentapartmentname2,
        managersname: this.address.who === 'Applicant' ? this.$store.getters.step3currentaddressmanagersname : this.$store.getters.step3currentaddressmanagersname2,
        telephone: this.address.who === 'Applicant' ? this.$store.getters.step3currentaddresstelephone : this.$store.getters.step3currentaddresstelephone2,
        address1: this.address.who === 'Applicant' ? this.$store.getters.step3currentaddressaddress1 : this.$store.getters.step3currentaddressaddress12,
        city: this.address.who === 'Applicant' ? this.$store.getters.step3currentaddresscity : this.$store.getters.step3currentaddresscity2,
        state: this.address.who === 'Applicant' ? this.$store.getters.step3currentaddressstate : this.$store.getters.step3currentaddressstate2,
        zip: this.address.who === 'Applicant' ? this.$store.getters.step3currentaddresszip : this.$store.getters.step3currentaddresszip2,
        rentamount: this.address.who === 'Applicant' ? this.$store.getters.step3currentaddressrentamount : this.$store.getters.step3currentaddressrentamount2,
        moveindate: this.address.who === 'Applicant' ? this.$store.getters.step3currentaddressmoveindate : this.$store.getters.step3currentaddressmoveindate2,
        moveindateyear: this.address.who === 'Applicant' ? this.$store.getters.step3currentaddressmoveindateyear : this.$store.getters.step3currentaddressmoveindate2year,
        reasonforleaving: this.address.who === 'Applicant' ? this.$store.getters.step3currentaddressreasonforleaving : this.$store.getters.step3currentaddressreasonforleaving2,
        currentapartmentnameplaceholder: this.$i18n.t('step3currentaddresscurrentapartmentname'),
        managersnameplaceholder: this.$i18n.t('step3currentaddressmanagersname'),
        telephoneplaceholder: this.$i18n.t('step3currentaddresstelephone'),
        address1placeholder: this.$i18n.t('step3currentaddressaddress1'),
        cityplaceholder: this.$i18n.t('step3currentaddresscity'),
        stateplaceholder: this.$i18n.t('step3currentaddressstate'),
        zipplaceholder: this.$i18n.t('step3currentaddresszip'),
        rentmortageamountplaceholder: this.$i18n.t('step3currentaddressrentmortgageamount'),
        moveindateplaceholder: this.$i18n.t('step3currentaddressmoveindate'),
        reasonforleavingplaceholder: this.$i18n.t('step3currentaddressreasonforleaving'),
        states: {
                  'AL': 'Alabama',
                  'AK': 'Alaska',
                  'AS': 'American Samoa',
                  'AZ': 'Arizona',
                  'AR': 'Arkansas',
                  'CA': 'California',
                  'CO': 'Colorado',
                  'CT': 'Connecticut',
                  'DE': 'Delaware',
                  'DC': 'District Of Columbia',
                  'FM': 'Federated States Of Micronesia',
                  'FL': 'Florida',
                  'GA': 'Georgia',
                  'GU': 'Guam',
                  'HI': 'Hawaii',
                  'ID': 'Idaho',
                  'IL': 'Illinois',
                  'IN': 'Indiana',
                  'IA': 'Iowa',
                  'KS': 'Kansas',
                  'KY': 'Kentucky',
                  'LA': 'Louisiana',
                  'ME': 'Maine',
                  'MH': 'Marshall Islands',
                  'MD': 'Maryland',
                  'MA': 'Massachusetts',
                  'MI': 'Michigan',
                  'MN': 'Minnesota',
                  'MS': 'Mississippi',
                  'MO': 'Missouri',
                  'MT': 'Montana',
                  'NE': 'Nebraska',
                  'NV': 'Nevada',
                  'NH': 'New Hampshire',
                  'NJ': 'New Jersey',
                  'NM': 'New Mexico',
                  'NY': 'New York',
                  'NC': 'North Carolina',
                  'ND': 'North Dakota',
                  'MP': 'Northern Mariana Islands',
                  'OH': 'Ohio',
                  'OK': 'Oklahoma',
                  'OR': 'Oregon',
                  'PW': 'Palau',
                  'PA': 'Pennsylvania',
                  'PR': 'Puerto Rico',
                  'RI': 'Rhode Island',
                  'SC': 'South Carolina',
                  'SD': 'South Dakota',
                  'TN': 'Tennessee',
                  'TX': 'Texas',
                  'UT': 'Utah',
                  'VT': 'Vermont',
                  'VI': 'Virgin Islands',
                  'VA': 'Virginia',
                  'WA': 'Washington',
                  'WV': 'West Virginia',
                  'WI': 'Wisconsin',
                  'WY': 'Wyoming'
                }
      }
    },
    computed: {
      emptyName () {
        return this.name === ''
      },
      years () {
        const year = new Date(new Date().setFullYear(new Date().getFullYear() + 11)).getFullYear()
        console.log('year')
        console.log(year)
        return Array.from({length: year - 1970}, (value, index) => 1970 + index)
      }
    },
    methods: {
      updateApartmentName (event) {
        const occup = event.target.value
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3currentaddresscurrentapartmentname', occup)
        } else {
          this.$store.dispatch('updateStep3currentaddresscurrentapartmentname2', occup)
        }
      },
      updateManagersname (event) {
        const occup = event.target.value
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3currentaddressmanagersname', occup)
        } else {
          this.$store.dispatch('updateStep3currentaddressmanagersname2', occup)
        }
      },
      updateTelephone (event) {
        const occup = event.target.value
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3currentaddresstelephone', occup)
        } else {
          this.$store.dispatch('updateStep3currentaddresstelephone2', occup)
        }
      },
      updateAddress (event) {
        const occup = event.target.value
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3currentaddressaddress1', occup)
        } else {
          this.$store.dispatch('updateStep3currentaddressaddress12', occup)
        }
      },
      updateCity (event) {
        const occup = event.target.value
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3currentaddresscity', occup)
        } else {
          this.$store.dispatch('updateStep3currentaddresscity2', occup)
        }
      },
      updateState (event) {
        const occup = event
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3currentaddressstate', occup)
        } else {
          this.$store.dispatch('updateStep3currentaddressstate2', occup)
        }
      },
      updateZip (event) {
        const occup = event.target.value
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3currentaddresszip', occup)
        } else {
          this.$store.dispatch('updateStep3currentaddresszip2', occup)
        }
      },
      updateRentAmount (event) {
        const occup = event.target.value
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3currentaddressrentamount', occup)
        } else {
          this.$store.dispatch('updateStep3currentaddressrentamount2', occup)
        }
      },
      updateMoveInDate (event) {
        const occup = event
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3currentaddressmoveindate', occup)
        } else {
          this.$store.dispatch('updateStep3currentaddressmoveindate2', occup)
        }
      },
      updateMoveInDateYear (event) {
        const occup = event
        console.log({where: '339 CurrentAddress MoveInDateYear', occup})
        this.$store.dispatch('updateStep3currentaddressmoveindateyear', occup)
      },
      updateReasonForLeaving (event) {
        const occup = event.target.value
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3currentaddressreasonforleaving', occup)
        } else {
          this.$store.dispatch('updateStep3currentaddressreasonforleaving2', occup)
        }
      }
    }
  }
</script>
