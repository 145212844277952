<template>
  <div class="container">
    <div class="container-fluid">
      <div class="panel panel-info">
        <div class="panel-body">
          <h1>
            {{$t('Step', {lng: this.$store.getters.language}) }} 7 {{$t('step7title', {lng:
            this.$store.getters.language})
            }}</h1>
        </div>
      </div>
      <div class="panel panel-info">
        <div class="panel-heading h2">{{step7creditcardinfoplaceholder}}</div>
        <div class="panel-body">
          <fees-table></fees-table>
          <div>
            <label class="h5" for="step7firstname"> {{step7firstnameplaceholder}}</label>
            <input
            type="text"
            class="form-control"
            :placeholder=step7firstnameplaceholder
            v-model="step7firstname"
            id="step7firstname"
            data-vv-name="step7firstname" v-validate="'required'"
            >
            <span style="color: darkred" v-show="errors.has('step7firstname')">required</span>
          </div>
          <div>
            <label class="h5" for="step7lastname"> {{step7lastnameplaceholder}}</label>
            <input
            type="text"
            class="form-control"
            :placeholder=step7lastnameplaceholder
            v-model="step7lastname"
            id="step7lastname"
            data-vv-name="step7lastname" v-validate="'required'"
            >
            <span style="color: darkred" v-show="errors.has('step7lastname')">required</span>
          </div>
          <div>
            <label class="h5" for="step7nameshownoncard"> {{step7nameshownoncardplaceholder}}</label>
            <input
            type="text"
            class="form-control"
            :placeholder=step7nameshownoncardplaceholder
            v-model="step7nameshownoncard"
            id="step7nameshownoncard"
            data-vv-name="step7nameshownoncard" v-validate="'required'"
            >
            <span style="color: darkred" v-show="errors.has('step7nameshownoncard')">required</span>
          </div>
          <div>
            <label class="h5" for="step7emailaddress"> {{step7emailaddressplaceholder}}</label>
            <input
            type="text"
            class="form-control"
            :placeholder=step7emailaddressplaceholder
            v-model="step7emailaddress"
            id="step7emailaddress"
            data-vv-name="step7emailaddress" data-vv-as="email" v-validate="'email'"
            >
            <span style="color: darkred" v-show="errors.has('step7emailaddress')">required</span>
          </div>
          <div>
            <label class="h5" for="step7address"> {{step7addressplaceholder}}</label>
            <input
            type="text"
            class="form-control"
            :placeholder=step7addressplaceholder
            v-model="step7address"
            id="step7address"
            data-vv-name="step7address" v-validate="'required'"
            >
            <span style="color: darkred" v-show="errors.has('step7address')">required</span>
          </div>
          <div>
            <label class="h5" for="step7city"> {{step7cityplaceholder}}</label>
            <input
            type="text"
            class="form-control"
            :placeholder=step7cityplaceholder
            v-model="step7city"
            id="step7city"
            data-vv-name="step7city" v-validate="'required'"
            >
            <span style="color: darkred" v-show="errors.has('step7city')">required</span>
          </div>
          <div>
            <label class="h5" for="step7state"> {{step7stateplaceholder}}</label>
            <b-form-select id="step7state" v-model="step7state"
                           data-vv-name="step7state" v-validate="'required'"
            >
              <option v-for="(item, index) in states" @value="index" :key="index">{{item}}</option>
            </b-form-select>
            <span style="color: darkred" v-show="errors.has('step7state')">required</span>
          </div>
          <div>
            <label class="h5" for="step7zip"> {{step7zipplaceholder}}</label>
            <input
            type="text"
            class="form-control"
            :placeholder=step7zipplaceholder
            v-model="step7zip"
            id="step7zip"
            v-mask="'#####'"
            data-vv-name="step7zip" v-validate="'required'"
            >
            <span style="color: darkred" v-show="errors.has('step7zip')">required</span>
          </div>
          <div>
            <label class="h5" for="step7creditcardtype"> {{step7creditcardtypeplaceholder}}</label>
            <b-form-select id="step7creditcardtype" v-model="step7creditcardtype" @change="onComponentChange"
                           data-vv-name="step7creditcardtype" v-validate="'required'"
            >
              <option value="VISA">VISA</option>
              <option value="MASTERCARD">MasterCard</option>
              <option value="AMEX">American Express</option>
              <option value="DISCOVER">Discover</option>
            </b-form-select>
            <span style="color: darkred" v-show="errors.has('step7creditcardtype')">required</span>
          </div>
          <div>
            <label class="h5" for="step7creditcardnumber"> {{step7creditcardnumberplaceholder}}</label>
            <input
            type="text"
            class="form-control"
            :placeholder=step7creditcardnumberplaceholder
            v-model="step7creditcardnumber"
            id="step7creditcardnumber"
            data-vv-name="step7creditcardnumber" v-validate="'required'"
            >
            <span style="color: darkred" v-show="errors.has('step7creditcardnumber')">required</span>
          </div>
          <div>
            <label class="h5" for="step7expirationdatecreditcard"> {{step7expirationdatecreditcardplaceholder}}</label>
            <b-row>
              <b-col>Month</b-col>
              <b-col>Year</b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-select id="step7expirationdatecreditcard" v-model="step7expirationdatecreditcard"
                               data-vv-name="step7expirationdatecreditcard" v-validate="'required'"
                >
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </b-form-select>
              </b-col>
              <b-col>
                <b-form-select id="step7expirationdatecreditcardyear" v-model="step7expirationdatecreditcardyear"
                               data-vv-name="step7expirationdatecreditcardyear" v-validate="'required'"
                >
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                  <option value="32">32</option>
                  <option value="33">33</option>
                  <option value="34">34</option>
                  <option value="35">35</option>
                  <option value="36">36</option>
                  <option value="37">37</option>
                  <option value="38">38</option>
                  <option value="39">39</option>
                </b-form-select>
              </b-col>
            </b-row>
            <span style="color: darkred" v-show="errors.has('step7expirationdatecreditcard')">required</span>
          </div>
          <div>
            <label class="h5" for="step7cvi"> {{step7cviplaceholder}}</label>
            <input
            type="text"
            class="form-control"
            :placeholder=step7cviplaceholder
            v-model="step7cvi"
            id="step7cvi"
            data-vv-name="step7cvi" v-validate="'required'"
            >
            <span style="color: darkred" v-show="errors.has('step7cvi')">required</span>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-footer">
      <button class="btn btn-success" type="submit" @click="Previous">
        {{$t('previous', {lng: this.$store.getters.language}) }}
      </button>
      <button class="btn btn-success" type="submit" @click="Next">{{$t('next', {lng: this.$store.getters.language}) }}
      </button>
    </div>
  </div>
</template>

<script>
  import FeesTable from './FeesTable.vue'
  export default {
    data () {
      return {
        isDropdownOpen: false,
        step7creditcardinfoplaceholder: this.$i18n.t('step7creditcardinfo'),
        step7firstnameplaceholder: this.$i18n.t('step7firstname'),
        step7lastnameplaceholder: this.$i18n.t('step7lastname'),
        step7nameshownoncardplaceholder: this.$i18n.t('step7nameshownoncard'),
        step7emailaddressplaceholder: this.$i18n.t('step7emailaddress'),
        step7addressplaceholder: this.$i18n.t('step7address'),
        step7cityplaceholder: this.$i18n.t('step7city'),
        step7stateplaceholder: this.$i18n.t('step7state'),
        step7zipplaceholder: this.$i18n.t('step7zip'),
        step7creditcardtypeplaceholder: this.$i18n.t('step7creditcardtype'),
        step7creditcardnumberplaceholder: this.$i18n.t('step7creditcardnumber'),
        step7expirationdatecreditcardplaceholder: this.$i18n.t('step7expirationdatecreditcard'),
        step7cviplaceholder: this.$i18n.t('step7cvi'),
        states: {
                  'AL': 'Alabama',
                  'AK': 'Alaska',
                  'AS': 'American Samoa',
                  'AZ': 'Arizona',
                  'AR': 'Arkansas',
                  'CA': 'California',
                  'CO': 'Colorado',
                  'CT': 'Connecticut',
                  'DE': 'Delaware',
                  'DC': 'District Of Columbia',
                  'FM': 'Federated States Of Micronesia',
                  'FL': 'Florida',
                  'GA': 'Georgia',
                  'GU': 'Guam',
                  'HI': 'Hawaii',
                  'ID': 'Idaho',
                  'IL': 'Illinois',
                  'IN': 'Indiana',
                  'IA': 'Iowa',
                  'KS': 'Kansas',
                  'KY': 'Kentucky',
                  'LA': 'Louisiana',
                  'ME': 'Maine',
                  'MH': 'Marshall Islands',
                  'MD': 'Maryland',
                  'MA': 'Massachusetts',
                  'MI': 'Michigan',
                  'MN': 'Minnesota',
                  'MS': 'Mississippi',
                  'MO': 'Missouri',
                  'MT': 'Montana',
                  'NE': 'Nebraska',
                  'NV': 'Nevada',
                  'NH': 'New Hampshire',
                  'NJ': 'New Jersey',
                  'NM': 'New Mexico',
                  'NY': 'New York',
                  'NC': 'North Carolina',
                  'ND': 'North Dakota',
                  'MP': 'Northern Mariana Islands',
                  'OH': 'Ohio',
                  'OK': 'Oklahoma',
                  'OR': 'Oregon',
                  'PW': 'Palau',
                  'PA': 'Pennsylvania',
                  'PR': 'Puerto Rico',
                  'RI': 'Rhode Island',
                  'SC': 'South Carolina',
                  'SD': 'South Dakota',
                  'TN': 'Tennessee',
                  'TX': 'Texas',
                  'UT': 'Utah',
                  'VT': 'Vermont',
                  'VI': 'Virgin Islands',
                  'VA': 'Virginia',
                  'WA': 'Washington',
                  'WV': 'West Virginia',
                  'WI': 'Wisconsin',
                  'WY': 'Wyoming'
                }
      }
    },
    watch: {
      '$route' (to) {
        this.language = to.query.language
      }
    },
    components: {
      FeesTable
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      if (this.$route.query.language) {
        this.language = this.$route.query.language
      } else {
        this.language = 'en'
      }
    },
    computed: {
      step7firstname: {
        get () {
          return this.$store.getters.step7firstname
        },
        set (value) {
          this.$store.dispatch('updateStep7firstname', value)
        }
      },
      step7lastname: {
        get () {
          return this.$store.getters.step7lastname
        },
        set (value) {
          this.$store.dispatch('updateStep7lastname', value)
        }
      },
      step7nameshownoncard: {
        get () {
          return this.$store.getters.step7nameshownoncard
        },
        set (value) {
          this.$store.dispatch('updateStep7nameshownoncard', value)
        }
      },
      step7emailaddress: {
        get () {
          return this.$store.getters.step7emailaddress
        },
        set (value) {
          this.$store.dispatch('updateStep7emailaddress', value)
        }
      },
      step7address: {
        get () {
          return this.$store.getters.step7address
        },
        set (value) {
          this.$store.dispatch('updateStep7address', value)
        }
      },
      step7city: {
        get () {
          return this.$store.getters.step7city
        },
        set (value) {
          this.$store.dispatch('updateStep7city', value)
        }
      },
      step7state: {
        get () {
          return this.$store.getters.step7state
        },
        set (value) {
          this.$store.dispatch('updateStep7state', value)
        }
      },
      step7zip: {
        get () {
          return this.$store.getters.step7zip
        },
        set (value) {
          this.$store.dispatch('updateStep7zip', value)
        }
      },
      step7creditcardtype: {
        get () {
          return this.$store.getters.step7creditcardtype
        },
        set (value) {
          this.$store.dispatch('updateStep7creditcardtype', value)
        }
      },
      step7creditcardnumber: {
        get () {
          return this.$store.getters.step7creditcardnumber
        },
        set (value) {
          this.$store.dispatch('updateStep7creditcardnumber', value)
        }
      },
      step7expirationdatecreditcard: {
        get () {
          return this.$store.getters.step7expirationdatecreditcard
        },
        set (value) {
          this.$store.dispatch('updateStep7expirationdatecreditcard', value)
        }
      },
      step7expirationdatecreditcardyear: {
        get () {
          return this.$store.getters.step7expirationdatecreditcardyear
        },
        set (value) {
          this.$store.dispatch('updateStep7expirationdatecreditcardyear', value)
        }
      },
      step7cvi: {
        get () {
          return this.$store.getters.step7cvi
        },
        set (value) {
          this.$store.dispatch('updateStep7cvi', value)
        }
      }
    },
    methods: {
      onComponentChange () {

      },
      pad (value, length) {
        return (value.toString().length < length) ? this.pad('0' + value, length) : value
      },
      Previous () {
        window.scrollTo(0, 0)
        this.$router.push({name: 'Step6'})
      },
      Next () {
        this.$validator.validateAll().then(() => {
          console.log('result')
          if (this.errors.items.length) {
            console.log('this.errors.items.length')
            console.log(this.errors.items.length)
          } else {
            console.log('no errors')
            window.scrollTo(0, 0)
            this.$router.push({name: 'Step8'})
          }
        })
      }
    }
  }
</script>
