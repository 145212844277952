<template>
  <div class="hello">
    <div v-if="complexPresent">
      <div class="jumbotron">
        <h1>
          <router-link to="/Step1" activeClass="active" tag="li" @click.native="English" style="cursor: pointer;">English</router-link>
        </h1>
      </div>
      <div class="jumbotron">
        <h1>
          <router-link to="/Step1" activeClass="active" tag="li" @click.native="Spanish" style="cursor: pointer;">{{$t('spanish', {lng: this.$store.getters.language}) }}</router-link>
        </h1>
      </div>
    </div>
    <div v-if="!complexPresent">
      You must come here from the link in the email sent to you.
    </div>
  </div>
</template>

<script>

export default {
  name: 'Online_Rental_Application',
  data () {
    return {
      lng: 'en',
      home: true,
      complexPresent: true,
      info: {}
    }
  },
  async created () {
    this.$store.dispatch('clearAllGlobal')
    const complexid = this.$route.query.id
    if (complexid) {
      this.complexPresent = true
    }
    const dorental = this.$route.query.dorental
    const doemployment = this.$route.query.doemployment
    const docoapp = this.$route.query.docoapp
    const doevictions = this.$route.query.doevictions
    const evictionstate = this.$route.query.evictionstate
    const coevictionstate = this.$route.query.coevictionstate

    this.$store.dispatch('setComplexid', complexid)
    this.$store.dispatch('setDorental', dorental)
    this.$store.dispatch('setDoemployment', doemployment)
    this.$store.dispatch('setDocoapp', docoapp)
    this.$store.dispatch('setDoevictions', doevictions)
    this.$store.dispatch('setEvictionstate', evictionstate)
    this.$store.dispatch('setCoevictionstate', coevictionstate)
    /*
        todo next use complexid to query packagescomplexes table and get following:
        backgroundcreditreportcost
        todo: if no records exists for previous then query packagescomplexes table for description equals standard
        and then get the field values of:
        packagesid
        description
        backgroundcreditreportcost
        and insert a new record with this data for the complexid
     */
    console.log('make call to uncomplexes')
    this.$http
      .get('https://a5s4422wvg.execute-api.us-east-1.amazonaws.com/dev/uncomplexes/' + complexid)
      .then((response) => {
        this.info = response.data.results
        this.$store.dispatch('setComplexname', response.data.results.complex.complexName)
        this.$store.dispatch('setComplexapplicationfee', response.data.results.complex.complex_application_fee)
        this.$store.dispatch('setRaapplicationfee', response.data.results.complex.ra_application_fee)
        this.$store.dispatch('setAdultapplicationfee', response.data.results.complex.adult_application_fee)
        this.$store.dispatch('setEmploymentreportprice', response.data.results.complex.employmentreportprice)
        this.$store.dispatch('setRentalreportprice', response.data.results.complex.rentalreportprice)
        this.$store.dispatch('setEvictionprice', response.data.results.complex.eviction_price)
      })
  },
  methods: {
    English () {
      this.$store.dispatch('setLanguage', 'en')
      this.$router.push({name: 'Step1'})
    },
    Spanish () {
      this.$store.dispatch('setLanguage', 'es')
      this.$router.push({name: 'Step1'})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
