import Vue from 'vue'
import Vuex from 'vuex'

import stocks from './modules/stocks'
import portfolio from './modules/portfolio'
import global from './modules/global'
import createPersistedState from 'vuex-persistedstate'

import * as actions from './actions'

Vue.use(Vuex)

export default new Vuex.Store({
  actions,
  modules: {
    stocks,
    portfolio,
    global
  },
  plugins: [createPersistedState()]
})
