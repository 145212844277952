import Vue from 'vue'
import Router from 'vue-router'
import Hello from '@/components/Hello'
import Step1 from '@/components/Step1'
import Step2 from '@/components/Step2'
import Step3 from '@/components/Step3'
import Step4 from '@/components/Step4'
import Step5 from '@/components/Step5'
import Step6 from '@/components/Step6'
import Step7 from '@/components/Step7'
import Step8 from '@/components/Step8'
import Step9 from '@/components/Step9'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Hello',
      component: Hello
    },
    {path: '/Step1', name: 'Step1', component: Step1, canReuse: false},
    {path: '/Step2', name: 'Step2', component: Step2, canReuse: false},
    {path: '/Step3', name: 'Step3', component: Step3, canReuse: false},
    {path: '/Step4', name: 'Step4', component: Step4, canReuse: false},
    {path: '/Step5', name: 'Step5', component: Step5, canReuse: false},
    {path: '/Step6', name: 'Step6', component: Step6, canReuse: false},
    {path: '/Step7', name: 'Step7', component: Step7, canReuse: false},
    {path: '/Step8', name: 'Step8', component: Step8, canReuse: false},
    {path: '/Step9', name: 'Step9', component: Step9, canReuse: false}
  ]
})

// prevent router duplication error. from https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
};
// end prevent router duplication code

export default router;