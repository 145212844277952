<template>
    <div class="col-sm-3 col-md-3">
        <div class="panel panel-info">
            <div class="panel-heading">
                <h3 class="panel-title">
                    Occupant: {{ occupant.id }}
                </h3>
            </div>
            <div class="panel-body">
                <div class="form-grouppull-left">
                    <input
                            type="hidden"
                            class="form-control"
                            placeholder="id"
                            v-model="id"
                            :class="{danger: emptyName}"
                    >
                    <input
                            class="form-control"
                            placeholder="Name"
                            v-model="name"
                            @input="updateName"
                            :class="{danger: emptyName}"
                    >
                    <input
                            class="form-control"
                            placeholder="Relationship"
                            v-model="relationship"
                            @input="updateRelationship"
                    >
                    <input
                            class="form-control"
                            placeholder="Age"
                            v-model="age"
                            @input="updateAge"
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .danger {
        border: 1px solid red;
    }
</style>

<script>
  import {mapActions} from 'vuex'

  export default {
    props: ['occupant'],
    data () {
      return {
        id: this.occupant.id,
        name: this.occupant.name,
        relationship: this.occupant.relationship,
        age: this.occupant.age
      }
    },
    computed: {
      emptyName () {
        return this.name === ''
      }
    },
    methods: {
      ...mapActions({
        updateOccupant: 'updateOccupnt'
      }),
      updateAge (event) {
        const occup = {id: this.id, name: this.name, relationship: this.relationship, age: event.target.value}
        this.$store.dispatch('updateOccupant', occup)
      },
      updateRelationship (event) {
        const occup = {id: this.id, name: this.name, relationship: event.target.value, age: this.age}
        this.$store.dispatch('updateOccupant', occup)
      },
      updateName (event) {
        const occup = {id: this.id, name: event.target.value, relationship: this.relationship, age: this.age}
        this.$store.dispatch('updateOccupant', occup)
      }
    }
  }
</script>
