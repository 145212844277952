<template>
  <div class="container">
        <div class="container-fluid">
            <div class="panel panel-info">
                <div class="panel-body">
                    {{$t('Step', {lng: this.$store.getters.language}) }} 5 {{$t('step5title', {lng: this.$store.getters.language}) }}
                </div>
            </div>
            <div>
                <app-reference :reference=whichapplicant1 :who="Applicant"></app-reference>
            </div>
            <div>
                <reference-second :reference=whichapplicant2 :who="Applicant"></reference-second>
            </div>
        </div>
        <div class="panel-footer">
            <button class="btn btn-success" type="submit" @click="Previous">
                {{$t('previous', {lng: this.$store.getters.language}) }}
            </button>
            <button class="btn btn-success" type="submit" @click="Next">{{$t('next', {lng: this.$store.getters.language}) }}</button>
        </div>
    </div>
</template>

<script>

  import Reference from './Reference.vue'
  import ReferenceSecond from './ReferenceSecond.vue'

  export default {
    data () {
      return {
        isDropdownOpen: false,
        reference: {},
        Applicant: ''
      }
    },
    components: {
      appReference: Reference,
      ReferenceSecond
    },
    watch: {
      '$route' (to) {
        this.language = to.query.language
      }
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.$i18n.i18next.changeLanguage(this.$store.getters.language)
      if (this.$route.query.language) {
        this.language = this.$route.query.language
      } else {
        this.language = 'en'
      }
    },
    computed: {
      whichapplicant1: {
        get () {
          this.$i18n.i18next.changeLanguage(this.$store.getters.language)
          let apl = this.$i18n.t('applicant')
          let one = 'One'
          if (this.$store.getters.language === 'es') {
            one = 'Uno'
          }
          return {who: apl, whichone: one, reference: 'Reference1'}
        }
      },
      whichapplicant2: {
        get () {
          this.$i18n.i18next.changeLanguage(this.$store.getters.language)
          let apl = this.$i18n.t('applicant')
          let one = 'Two'
          if (this.$store.getters.language === 'es') {
            one = 'Dos'
          }
          return {who: apl, whichone: one, reference: 'Reference2'}
        }
      }
    },
    methods: {
      Previous () {
        window.scrollTo(0, 0)
        this.$router.push({name: 'Step4'})
      },
      Next () {
        this.$validator.validateAll().then(() => {
          if (this.errors.items.length) {
            console.log('this.errors.items.length')
            console.log(this.errors.items.length)
          } else {
            console.log('no errors')
            window.scrollTo(0, 0)
            this.$router.push({name: 'Step6'})
          }
        })
      }
    }
  }
</script>
