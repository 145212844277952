<template>
  <div class="col-sm-12 col-md-12">
    <div class="panel panel-info">
      <div class="panel-heading">
        <h3 class="panel-title">
          {{ employment.who }}'s {{currentemploymentinfo}}: {{ employment.id }}
        </h3>
      </div>
      <div class="panel-body">
        <div class="form-grouppull-left">
          <input
            type="hidden"
            class="form-control"
            placeholder="id"
            v-model="id"
            :class="{danger: emptyName}"
          >
          <div class="checkbox">
            <label>
              <input @change="checknotcurrentlyemployed($event)" v-model="notcurrentlyemployed" type="checkbox"> {{step4notcurrentlyemployed}}.
            </label>
          </div>
          <div>
            <label for="companyname"> {{step4companynameplaceholder}}</label>
            <input
              class="form-control"
              :placeholder="step4companynameplaceholder"
              v-model="companyname"
              id="companyname"
              @input="updateCompanyName"
              :class="{danger: emptyName}"
              data-vv-name="companyname" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('companyname')">required</span>
          </div>
          <div>
            <label for="supervisorsname"> {{supervisorsnameplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="supervisorsnameplaceholder"
            v-model="supervisorsname"
            id="supervisorsname"
            @input="updateSupervisorsname"
            data-vv-name="supervisorsname" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('supervisorsname')">required</span>
          </div>
          <div>
            <label for="telephone"> {{supervisorsnameplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="telephoneplaceholder"
            v-model="telephone"
            id="telephone"
            @input="updateTelephone"
            data-vv-name="telephone" v-validate="rules"
            v-mask="'(###)-###-####'"
            >
            <span style="color: darkred" v-show="errors.has('telephone')">required</span>
          </div>
          <div>
            <label for="address1"> {{address1placeholder}}</label>
            <input
            class="form-control"
            :placeholder="address1placeholder"
            v-model="address1"
            id="address1"
            @input="updateAddress"
            data-vv-name="address1" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('address1')">required</span>
          </div>
          <div>
            <label for="city"> {{cityplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="cityplaceholder"
            v-model="city"
            id="city"
            @input="updateCity"
            data-vv-name="city" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('city')">required</span>
          </div>
          <div>
            <label for="state"> {{stateplaceholder}}</label>
            <b-form-select id="state" v-model="state" @change="updateState"
                           data-vv-name="state" v-validate="rules"
            >
              <option v-for="(item, index) in states" @value="index" :key="index">{{item}}</option>
            </b-form-select>
            <span style="color: darkred" v-show="errors.has('state')">required</span>
          </div>
          <div>
            <label for="zip"> {{zipplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="zipplaceholder"
            v-model="zip"
            id="zip"
            @input="updateZip"
            v-mask="'#####'"
            data-vv-name="zip" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('zip')">required</span>
          </div>
          <div>
            <label for="salary"> {{grossmonthlysalaryplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="grossmonthlysalaryplaceholder"
            v-model="salary"
            id="salary"
            @input="updateSalary"
            data-vv-name="salary" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('salary')">required</span>
          </div>
          <div>
            <label for="hoursperweek"> {{hoursperweekplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="hoursperweekplaceholder"
            v-model="hoursperweek"
            id="hoursperweek"
            @input="updateHoursperweek"
            data-vv-name="hoursperweek" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('hoursperweek')">required</span>
          </div>
          <div>
            <label for="startdate"> {{startdateplaceholder}}</label>
            <b-row>
              <b-col>Month</b-col>
              <b-col>Year</b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-select id="startdate" v-model="startdate"
                               data-vv-name="startdate" v-validate="rules"
                               @input="updateStartDate"
                >
                  <option v-for="month in months" :value="month" :key="month">{{ month }}</option>
                </b-form-select>
              </b-col>
              <b-col>
                <b-form-select id="startdateyear" v-model="startdateyear"
                               data-vv-name="startdateyear" v-validate="rules"
                               @input="updateStartDateYear"
                >
                  <option value="0">Year:</option>
                  <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                </b-form-select>
              </b-col>
            </b-row>
            <span style="color: darkred" v-show="errors.has('startdate')">required</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .danger {
    border: 1px solid red;
  }
</style>

<script>

  export default {
    props: ['employment'],
    inject: ['$validator'],
    data () {
      return {
        id: this.employment.id,
        notcurrentlyemployed: this.$store.getters.step4notcurrentlyemployed,
        companyname: this.employment.who === 'Applicant' ? this.$store.getters.step4currentemploymentcompanyname : this.$store.getters.step4currentemploymentcompanyname2,
        supervisorsname: this.employment.who === 'Applicant' ? this.$store.getters.step4currentemploymentsupervisorsname : this.$store.getters.step4currentemploymentsupervisorsname2,
        telephone: this.employment.who === 'Applicant' ? this.$store.getters.step4currentemploymenttelephone : this.$store.getters.step4currentemploymenttelephone2,
        address1: this.employment.who === 'Applicant' ? this.$store.getters.step4currentemploymentaddress1 : this.$store.getters.step4currentemploymentaddress12,
        city: this.employment.who === 'Applicant' ? this.$store.getters.step4currentemploymentcity : this.$store.getters.step4currentemploymentcity2,
        state: this.employment.who === 'Applicant' ? this.$store.getters.step4currentemploymentstate : this.$store.getters.step4currentemploymentstate2,
        zip: this.employment.who === 'Applicant' ? this.$store.getters.step4currentemploymentzip : this.$store.getters.step4currentemploymentzip2,
        salary: this.employment.who === 'Applicant' ? this.$store.getters.step4currentemploymentsalary : this.$store.getters.step4currentemploymentsalary2,
        hoursperweek: this.employment.who === 'Applicant' ? this.$store.getters.step4currentemploymenthoursperweek : this.$store.getters.step4currentemploymenthoursperweek2,
        startdate: this.employment.who === 'Applicant' ? this.$store.getters.step4currentemploymentstartdate : this.$store.getters.step4currentemploymentstartdate2,
        startdateyear: this.employment.who === 'Applicant' ? this.$store.getters.step4currentemploymentstartdateyear : this.$store.getters.step4currentemploymentstartdate2year,
        enddate: this.employment.who === 'Applicant' ? this.$store.getters.step4currentemploymentenddate : this.$store.getters.step4currentemploymentenddate2,
        enddateyear: this.employment.who === 'Applicant' ? this.$store.getters.step4currentemploymentenddateyear : this.$store.getters.step4currentemploymentenddate2year,
        reasonforleaving: this.employment.who === 'Applicant' ? this.$store.getters.step4currentemploymentreasonforleaving : this.$store.getters.step4currentemploymentreasonforleaving2,
        currentemploymentinfo: this.$i18n.t('step4currentemploymentinfo'),
        step4notcurrentlyemployed: this.$i18n.t('step4notcurrentlyemployed'),
        step4companynameplaceholder: this.$i18n.t('step4companyname'),
        supervisorsnameplaceholder: this.$i18n.t('step4supervisorsname'),
        telephoneplaceholder: this.$i18n.t('step4telephone'),
        address1placeholder: this.$i18n.t('step4address1'),
        cityplaceholder: this.$i18n.t('step4city'),
        stateplaceholder: this.$i18n.t('step4state'),
        zipplaceholder: this.$i18n.t('step4zip'),
        grossmonthlysalaryplaceholder: this.$i18n.t('step4grossmonthlysalary'),
        hoursperweekplaceholder: this.$i18n.t('step4hoursperweek'),
        startdateplaceholder: this.$i18n.t('step4startdate'),
        enddateplaceholder: this.$i18n.t('step4enddate'),
        reasonforleavingplaceholder: this.$i18n.t('step4reasonforleaving'),
        states: {
                  'AL': 'Alabama',
                  'AK': 'Alaska',
                  'AS': 'American Samoa',
                  'AZ': 'Arizona',
                  'AR': 'Arkansas',
                  'CA': 'California',
                  'CO': 'Colorado',
                  'CT': 'Connecticut',
                  'DE': 'Delaware',
                  'DC': 'District Of Columbia',
                  'FM': 'Federated States Of Micronesia',
                  'FL': 'Florida',
                  'GA': 'Georgia',
                  'GU': 'Guam',
                  'HI': 'Hawaii',
                  'ID': 'Idaho',
                  'IL': 'Illinois',
                  'IN': 'Indiana',
                  'IA': 'Iowa',
                  'KS': 'Kansas',
                  'KY': 'Kentucky',
                  'LA': 'Louisiana',
                  'ME': 'Maine',
                  'MH': 'Marshall Islands',
                  'MD': 'Maryland',
                  'MA': 'Massachusetts',
                  'MI': 'Michigan',
                  'MN': 'Minnesota',
                  'MS': 'Mississippi',
                  'MO': 'Missouri',
                  'MT': 'Montana',
                  'NE': 'Nebraska',
                  'NV': 'Nevada',
                  'NH': 'New Hampshire',
                  'NJ': 'New Jersey',
                  'NM': 'New Mexico',
                  'NY': 'New York',
                  'NC': 'North Carolina',
                  'ND': 'North Dakota',
                  'MP': 'Northern Mariana Islands',
                  'OH': 'Ohio',
                  'OK': 'Oklahoma',
                  'OR': 'Oregon',
                  'PW': 'Palau',
                  'PA': 'Pennsylvania',
                  'PR': 'Puerto Rico',
                  'RI': 'Rhode Island',
                  'SC': 'South Carolina',
                  'SD': 'South Dakota',
                  'TN': 'Tennessee',
                  'TX': 'Texas',
                  'UT': 'Utah',
                  'VT': 'Vermont',
                  'VI': 'Virgin Islands',
                  'VA': 'Virginia',
                  'WA': 'Washington',
                  'WV': 'West Virginia',
                  'WI': 'Wisconsin',
                  'WY': 'Wyoming'
                }
      }
    },
    computed: {
      emptyName () {
        return this.name === ''
      },
      rules () {
        return !this.notcurrentlyemployed ? 'required' : ''
      },
      years () {
        const year = new Date(new Date().setFullYear(new Date().getFullYear() + 11)).getFullYear()
        return Array.from({length: year - 1970}, (value, index) => 1970 + index)
      },
      months () {
        const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
        return months
      }
    },
    methods: {
      checknotcurrentlyemployed () {
        this.$store.dispatch('updateStep4NotCurrentlyEmployed', this.notcurrentlyemployed)
      },
      updateTelephone (event) {
        const occup = event.target.value
        if (this.employment.who === 'Applicant') {
          this.$store.dispatch('updateStep4currentemploymenttelephone', occup)
        } else {
          this.$store.dispatch('updateStep4currentemploymenttelephone2', occup)
        }
      },
      updateSupervisorsname (event) {
        const occup = event.target.value
        if (this.employment.who === 'Applicant') {
          this.$store.dispatch('updateStep4currentemploymentsupervisorsname', occup)
        } else {
          this.$store.dispatch('updateStep4currentemploymentsupervisorsname2', occup)
        }
      },
      updateCompanyName (event) {
        const occup = event.target.value
        if (this.employment.who === 'Applicant') {
          this.$store.dispatch('updateStep4currentemploymentcompanyname', occup)
        } else {
          this.$store.dispatch('updateStep4currentemploymentcompanyname2', occup)
        }
      },
      updateAddress (event) {
        const occup = event.target.value
        if (this.employment.who === 'Applicant') {
          this.$store.dispatch('updateStep4currentemploymentaddress1', occup)
        } else {
          this.$store.dispatch('updateStep4currentemploymentaddress12', occup)
        }
      },
      updateCity (event) {
        const occup = event.target.value
        if (this.employment.who === 'Applicant') {
          this.$store.dispatch('updateStep4currentemploymentcity', occup)
        } else {
          this.$store.dispatch('updateStep4currentemploymentcity2', occup)
        }
      },
      updateState (event) {
        const occup = event
        if (this.employment.who === 'Applicant') {
          this.$store.dispatch('updateStep4currentemploymentstate', occup)
        } else {
          this.$store.dispatch('updateStep4currentemploymentstate2', occup)
        }
      },
      updateZip (event) {
        const occup = event.target.value
        if (this.employment.who === 'Applicant') {
          this.$store.dispatch('updateStep4currentemploymentzip', occup)
        } else {
          this.$store.dispatch('updateStep4currentemploymentzip2', occup)
        }
      },
      updateSalary (event) {
        const occup = event.target.value
        if (this.employment.who === 'Applicant') {
          this.$store.dispatch('updateStep4currentemploymentsalary', occup)
        } else {
          this.$store.dispatch('updateStep4currentemploymentsalary2', occup)
        }
      },
      updateStartDate (event) {
        const occup = event
        if (this.employment.who === 'Applicant') {
          this.$store.dispatch('updateStep4currentemploymentstartdate', occup)
        } else {
          this.$store.dispatch('updateStep4currentemploymentstartdate2', occup)
        }
      },
      updateStartDateYear (event) {
        const occup = event
        if (this.employment.who === 'Applicant') {
          this.$store.dispatch('updateStep4currentemploymentstartdateyear', occup)
        } else {
          this.$store.dispatch('updateStep4currentemploymentstartdate2year', occup)
        }
      },
      updateEndDate (event) {
        const occup = event
        if (this.employment.who === 'Applicant') {
          this.$store.dispatch('updateStep4currentemploymentenddate', occup)
        } else {
          this.$store.dispatch('updateStep4currentemploymentenddate2', occup)
        }
      },
      updateEndDateYear (event) {
        const occup = event
        if (this.employment.who === 'Applicant') {
          this.$store.dispatch('updateStep4currentemploymentenddateyear', occup)
        } else {
          this.$store.dispatch('updateStep4currentemploymentenddate2year', occup)
        }
      },
      updateHoursperweek (event) {
        const occup = event.target.value
        if (this.employment.who === 'Applicant') {
          this.$store.dispatch('updateStep4currentemploymenthoursperweek', occup)
        } else {
          this.$store.dispatch('updateStep4currentemploymenthoursperweek2', occup)
        }
      },
      updateReasonForLeaving (event) {
        const occup = event.target.value
        if (this.employment.who === 'Applicant') {
          this.$store.dispatch('updateStep4currentemploymentreasonforleaving', occup)
        } else {
          this.$store.dispatch('updateStep4currentemploymentreasonforleaving2', occup)
        }
      }
    }
  }
</script>
