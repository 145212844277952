<template>
  <div class="container">
        <div class="panel panel-info">
            <div class="panel-body">
                <h3 class="panel-title">
                    {{$t('Step', {lng: this.$store.getters.language}) }} 2 {{$t('StepDescription2', {lng: this.$store.getters.language}) }}
                </h3>
            </div>
        </div>
        <div class="panel panel-info">
            <div class="panel-heading">
                <h3 class="panel-title">
                    {{$t('Step2HeadOfHousehold', {lng: this.$store.getters.language}) }}
                </h3>
            </div>
            <div class="panel-body">
                <form>
                    <div class="form-group ">
                        <label for="firstname" class="">{{$t('Step2ApplicantFirstName', {lng: this.$store.getters.language}) }}</label>
                        <div class="">
                            <input name="firstname" id="firstname"
                                      class="form-control"
                                      v-model="step2applicantfirstname"
                                   data-vv-name="firstname" v-validate="'required'"
                            />
                          <span style="color: darkred" v-show="errors.has('firstname')">required</span>
                        </div>
                    </div>
                    <div class="form-group ">
                        <label for="lastname" class="">{{$t('Step2ApplicantLastName', {lng: this.$store.getters.language}) }}</label>
                        <div class="">
                            <input name="lastname" id="lastname"
                                      class="form-control"
                                      v-model="step2applicantlastname"
                                   data-vv-name="lastname" v-validate="'required'"
                            />
                          <span style="color: darkred" v-show="errors.has('lastname')">required</span>
                        </div>
                    </div>
                    <div class="form-group ">
                        <label for="step2applicantssn1" class="">{{$t('Step2ApplicantSSN1', {lng: this.$store.getters.language}) }}</label>
                        <div class="">
                            <input name="step2applicantssn1" id="step2applicantssn1"
                                      class="form-control"
                                      v-model="step2applicantssn1"
                                   data-vv-name="step2applicantssn1" v-validate="'required'"
                                   v-mask="'###-##-####'" placeholder="###-##-####"
                            />
                          <span style="color: darkred" v-show="errors.has('step2applicantssn1')">required</span>
                        </div>
                    </div>
                    <div class="form-group ">
                        <label for="step2applicantssn2" class="">{{$t('Step2ApplicantSSN2', {lng: this.$store.getters.language}) }}</label>
                        <div class="">
                            <input name="step2applicantssn2" id="step2applicantssn2"
                                      class="form-control"
                                      v-model="step2applicantssn2"
                                   data-vv-name="step2applicantssn2" v-validate="'required'"
                                   v-mask="'###-##-####'" placeholder="###-##-####"
                            />
                          <span style="color: darkred" v-show="errors.has('step2applicantssn2')">required</span>
                          <span v-if="ssnError !== ''" style="color: darkred">required</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="step2applicantdob">{{$t('Step2ApplicantDOB', {lng: this.$store.getters.language}) }}</label>
                        <datepicker v-model="step2applicantdob" id="step2applicantdob" placeholder="mm-dd-yyyy" :config="{ dateFormat: 'm-d-Y', static: true }"
                                    data-vv-name="step2applicantdob" v-validate="'required'"
                        ></datepicker>
                      <span style="color: darkred" v-show="errors.has('step2applicantdob')">required</span>
                    </div>
                    <div class="form-group ">
                        <label for="step2applicantphone" class="">{{$t('Step2ApplicantPhone', {lng: this.$store.getters.language}) }}</label>
                        <div class="">
                            <input name="step2applicantphone" id="step2applicantphone"
                                      class="form-control"
                                      v-model="step2applicantphone"
                                   data-vv-name="step2applicantphone" v-validate="'required'"
                                   v-mask="'(###)-###-####'"
                            />
                          <span style="color: darkred" v-show="errors.has('step2applicantphone')">required</span>
                        </div>
                    </div>
                    <div class="form-group ">
                        <label for="step2applicantdriverslicense" class="">{{$t('Step2ApplicantDriversLicense', {lng: this.$store.getters.language}) }}</label>
                        <div class="">
                            <input name="step2applicantdriverslicense" id="step2applicantdriverslicense"
                                      class="form-control"
                                      v-model="step2applicantdriverslicense"
                                   data-vv-name="step2applicantdriverslicense" v-validate="'required'"
                            />
                          <span style="color: darkred" v-show="errors.has('step2applicantdriverslicense')">required</span>
                        </div>
                    </div>
                    <div class="form-group ">
                        <label for="step2applicantdriverslicensestate" class="">{{$t('Step2ApplicantDriversLicenseState', {lng: this.$store.getters.language}) }}</label>
                        <div class="">
                          <b-form-select name="step2applicantdriverslicensestate" id="step2applicantdriverslicensestate" v-model="step2applicantdriverslicensestate"
                                         data-vv-name="step2applicantdriverslicensestate" v-validate="'required'"
                          >
                            <option v-for="(item, index) in states" @value="index" :key="index">{{item}}</option>
                          </b-form-select>
                          <span style="color: darkred" v-show="errors.has('step2applicantdriverslicensestate')">required</span>
                        </div>
                    </div>
                    <div class="form-group ">
                        <label for="step2applicantsmoking" class="">{{$t('Step2ApplicantSmoking', {lng: this.$store.getters.language}) }}</label>
                        <b-form-select name="step2applicantsmoking" id="step2applicantsmoking"
                                       v-model="step2applicantsmoking"
                                       data-vv-name="step2applicantsmoking" v-validate="'required'">
                          <option :value="null">Please select an option</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </b-form-select>
                        <span style="color: darkred" v-show="errors.has('step2applicantsmoking')">required</span>
                    </div>
                    <div class="form-group ">
                        <label for="step2applicantemail" class="">{{$t('Step2ApplicantEmail', {lng: this.$store.getters.language}) }}</label>
                        <div class="">
                            <input name="step2applicantemail" id="step2applicantemail"
                                      class="form-control"
                                      v-model="step2applicantemail"
                                   data-vv-name="step2applicantemail" data-vv-as="email"  v-validate="'email'"
                            />
                          <span style="color: darkred" v-show="errors.has('step2applicantemail')">required</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="panel panel-info" v-if="displayCo != 'false'">
            <div class="panel-heading">
                <h3 class="panel-title">
                    {{$t('Step1CoApplicant', {lng: this.$store.getters.language}) }}
                </h3>
            </div>
            <div class="panel-body">
                <form>
                    <div class="form-group ">
                        <label for="cofirstname" class="">{{$t('Step2ApplicantFirstName', {lng: this.$store.getters.language}) }}</label>
                        <div class="">
                            <input name="cofirstname" id="cofirstname"
                                   class="form-control"
                                   v-model="step2coapplicantfirstname"
                                   data-vv-name="cofirstname" v-validate="'required'"
                            />
                          <span style="color: darkred" v-show="errors.has('cofirstname')">required</span>
                        </div>
                    </div>
                    <div class="form-group ">
                        <label for="colastname" class="">{{$t('Step2ApplicantLastName', {lng: this.$store.getters.language}) }}</label>
                        <div class="">
                            <input name="colastname" id="colastname"
                                   class="form-control"
                                   v-model="step2coapplicantlastname"
                                   data-vv-name="colastname" v-validate="'required'"
                            />
                          <span style="color: darkred" v-show="errors.has('colastname')">required</span>
                        </div>
                    </div>
                    <div class="form-group ">
                        <label for="step2coapplicantssn1" class="">{{$t('Step2ApplicantSSN1', {lng: this.$store.getters.language}) }}</label>
                        <div class="">
                            <b-form-input name="step2coapplicantssn1" id="step2coapplicantssn1"
                                   class="form-control"
                                   v-model="step2coapplicantssn1"
                                   data-vv-name="step2coapplicantssn1" v-validate="'required'"
                                   v-mask="'###-##-####'" placeholder="###-##-####"
                            ></b-form-input>
                          <span style="color: darkred" v-show="errors.has('step2coapplicantssn1')">required</span>
                        </div>
                    </div>
                    <div class="form-group ">
                        <label for="step2coapplicantssn2" class="">{{$t('Step2ApplicantSSN2', {lng: this.$store.getters.language}) }}</label>
                        <div class="">
                            <b-form-input name="step2coapplicantssn2name" id="step2coapplicantssn2"
                                   class="form-control"
                                   v-model="step2coapplicantssn2"
                                   data-vv-name="step2coapplicantssn2" v-validate="'required'"
                                   v-mask="'###-##-####'" placeholder="###-##-####"
                            />
                          <span style="color: darkred" v-show="errors.has('step2coapplicantssn2')">required</span>
                          <span v-if="ssnCoError !== ''" style="color: darkred">required</span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="step2coapplicantdob">{{$t('Step2ApplicantDOB', {lng: this.$store.getters.language}) }}</label>
                        <datepicker v-model="step2coapplicantdob" id="step2coapplicantdob" placeholder="mm-dd-yyyy" :config="{ dateFormat: 'm-d-Y', static: true }"
                                    data-vv-name="step2coapplicantdob" v-validate="'required'"
                        ></datepicker>
                      <span style="color: darkred" v-show="errors.has('step2coapplicantdob')">required</span>
                    </div>
                    <div class="form-group ">
                        <label for="step2coapplicantphone" class="">{{$t('Step2ApplicantPhone', {lng: this.$store.getters.language}) }}</label>
                        <div class="">
                            <input name="step2coapplicantphone" id="step2coapplicantphone"
                                   class="form-control"
                                   v-model="step2coapplicantphone"
                                   data-vv-name="step2coapplicantphone" v-validate="'required'"
                                   v-mask="'(###)-###-####'" placeholder="(###)-###-####"
                            />
                          <span style="color: darkred" v-show="errors.has('step2coapplicantphone')">required</span>
                        </div>
                    </div>
                    <div class="form-group ">
                        <label for="step2coapplicantdriverslicense" class="">{{$t('Step2ApplicantDriversLicense', {lng: this.$store.getters.language}) }}</label>
                        <div class="">
                            <input name="step2coapplicantdriverslicense" id="step2coapplicantdriverslicense"
                                   class="form-control"
                                   v-model="step2coapplicantdriverslicense"
                                   data-vv-name="step2coapplicantdriverslicense" v-validate="'required'"
                            />
                          <span style="color: darkred" v-show="errors.has('step2coapplicantdriverslicense')">required</span>
                        </div>
                    </div>
                    <div class="form-group ">
                        <label for="step2coapplicantdriverslicensestate" class="">{{$t('Step2ApplicantDriversLicenseState', {lng: this.$store.getters.language}) }}</label>
                        <div class="">
                          <b-form-select name="step2coapplicantdriverslicensestate" id="step2coapplicantdriverslicensestate" v-model="step2coapplicantdriverslicensestate"
                                         data-vv-name="step2coapplicantdriverslicensestate" v-validate="'required'"
                          >
                            <option v-for="(item, index) in states" @value="index" :key="index">{{item}}</option>
                          </b-form-select>
                          <span style="color: darkred" v-show="errors.has('step2coapplicantdriverslicensestate')">required</span>
                        </div>
                    </div>
                    <div class="form-group ">
                        <label for="step2coapplicantsmoking" class="">{{$t('Step2ApplicantSmoking', {lng: this.$store.getters.language}) }}</label>
                        <b-form-select name="step2coapplicantsmoking" id="step2coapplicantsmoking"
                                       v-model="step2coapplicantsmoking"
                                       data-vv-name="step2coapplicantsmoking" v-validate="'required'">
                          <option :value="null">Please select an option</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </b-form-select>
                        <span style="color: darkred" v-show="errors.has('step2coapplicantsmoking')">required</span>
                    </div>
                    <div class="form-group ">
                        <label for="step2coapplicantemail" class="">{{$t('Step2ApplicantEmail', {lng: this.$store.getters.language}) }}</label>
                        <div class="">
                            <input name="step2coapplicantemail" id="step2coapplicantemail"
                                   class="form-control"
                                   v-model="step2coapplicantemail"
                                   type="text"
                                   data-vv-name="step2coapplicantemail" data-vv-as="email" v-validate="'email'"
                            />
                          <span style="color: darkred" v-show="errors.has('step2coapplicantemail')">required</span>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="panel-footer">
            <button class="btn btn-success" type="submit" @click="Previous">
                {{$t('previous', {lng: this.$store.getters.language}) }}
            </button>
            <button class="btn btn-success" type="submit" @click="Next">{{$t('next', {lng: this.$store.getters.language}) }}</button>
        </div>
    </div>
</template>

<script>

  import Datepicker from 'vue-bulma-datepicker'

  export default {
    data () {
      return {
        lng: 'en',
        displayCo: this.$store.getters.docoapp,
        isDropdownOpen: false,
        states: {
                  'AL': 'Alabama',
                  'AK': 'Alaska',
                  'AS': 'American Samoa',
                  'AZ': 'Arizona',
                  'AR': 'Arkansas',
                  'CA': 'California',
                  'CO': 'Colorado',
                  'CT': 'Connecticut',
                  'DE': 'Delaware',
                  'DC': 'District Of Columbia',
                  'FM': 'Federated States Of Micronesia',
                  'FL': 'Florida',
                  'GA': 'Georgia',
                  'GU': 'Guam',
                  'HI': 'Hawaii',
                  'ID': 'Idaho',
                  'IL': 'Illinois',
                  'IN': 'Indiana',
                  'IA': 'Iowa',
                  'KS': 'Kansas',
                  'KY': 'Kentucky',
                  'LA': 'Louisiana',
                  'ME': 'Maine',
                  'MH': 'Marshall Islands',
                  'MD': 'Maryland',
                  'MA': 'Massachusetts',
                  'MI': 'Michigan',
                  'MN': 'Minnesota',
                  'MS': 'Mississippi',
                  'MO': 'Missouri',
                  'MT': 'Montana',
                  'NE': 'Nebraska',
                  'NV': 'Nevada',
                  'NH': 'New Hampshire',
                  'NJ': 'New Jersey',
                  'NM': 'New Mexico',
                  'NY': 'New York',
                  'NC': 'North Carolina',
                  'ND': 'North Dakota',
                  'MP': 'Northern Mariana Islands',
                  'OH': 'Ohio',
                  'OK': 'Oklahoma',
                  'OR': 'Oregon',
                  'PW': 'Palau',
                  'PA': 'Pennsylvania',
                  'PR': 'Puerto Rico',
                  'RI': 'Rhode Island',
                  'SC': 'South Carolina',
                  'SD': 'South Dakota',
                  'TN': 'Tennessee',
                  'TX': 'Texas',
                  'UT': 'Utah',
                  'VT': 'Vermont',
                  'VI': 'Virgin Islands',
                  'VA': 'Virginia',
                  'WA': 'Washington',
                  'WV': 'West Virginia',
                  'WI': 'Wisconsin',
                  'WY': 'Wyoming'
                },
        ssnError: '',
        ssnCoError: ''
      }
    },
    components: {
      Datepicker
    },
    watch: {
      '$route' (to) {
        this.language = to.query.language
      }
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      if (this.$route.query.lng) {
        this.language = this.$route.query.language
      } else {
        this.language = 'en'
      }
    },
    computed: {
      step2applicantfirstname: {
        get () {
          return this.$store.getters.step2applicantfirstname
        },
        set (value) {
          this.$store.dispatch('updateStep2applicantfirstname', value)
        }
      },
      step2applicantlastname: {
        get () {
          return this.$store.getters.step2applicantlastname
        },
        set (value) {
          this.$store.dispatch('updateStep2applicantlastname', value)
        }
      },
      step2applicantssn1: {
        get () {
          return this.$store.getters.step2applicantssn1
        },
        set (value) {
          this.$store.dispatch('updateStep2applicantssn1', value)
        }
      },
      step2applicantssn2: {
        get () {
          return this.$store.getters.step2applicantssn2
        },
        set (value) {
          this.$store.dispatch('updateStep2applicantssn2', value)
        }
      },
      step2applicantphone: {
        get () {
          return this.$store.getters.step2applicantphone
        },
        set (value) {
          this.$store.dispatch('updateStep2applicantphone', value)
        }
      },
      step2applicantdob: {
        get () {
          return this.$store.getters.step2applicantdob
        },
        set (value) {
          this.$store.dispatch('updateStep2applicantdob', value)
        }
      },
      step2applicantdriverslicense: {
        get () {
          return this.$store.getters.step2applicantdriverslicense
        },
        set (value) {
          this.$store.dispatch('updateStep2applicantdriverslicense', value)
        }
      },
      step2applicantdriverslicensestate: {
        get () {
          return this.$store.getters.step2applicantdriverslicensestate
        },
        set (value) {
          this.$store.dispatch('updateStep2applicantdriverslicensestate', value)
        }
      },
      step2applicantsmoking: {
        get () {
          return this.$store.getters.step2applicantsmoking
        },
        set (value) {
          this.$store.dispatch('updateStep2applicantsmoking', value)
        }
      },
      step2applicantemail: {
        get () {
          return this.$store.getters.step2applicantemail
        },
        set (value) {
          this.$store.dispatch('updateStep2applicantemail', value)
        }
      },
      step2coapplicantfirstname: {
        get () {
          return this.$store.getters.step2coapplicantfirstname
        },
        set (value) {
          this.$store.dispatch('updateStep2coapplicantfirstname', value)
        }
      },
      step2coapplicantlastname: {
        get () {
          return this.$store.getters.step2coapplicantlastname
        },
        set (value) {
          this.$store.dispatch('updateStep2coapplicantlastname', value)
        }
      },
      step2coapplicantssn1: {
        get () {
          return this.$store.getters.step2coapplicantssn1
        },
        set (value) {
          this.$store.dispatch('updateStep2coapplicantssn1', value)
        }
      },
      step2coapplicantssn2: {
        get () {
          return this.$store.getters.step2coapplicantssn2
        },
        set (value) {
          this.$store.dispatch('updateStep2coapplicantssn2', value)
        }
      },
      step2coapplicantphone: {
        get () {
          return this.$store.getters.step2coapplicantphone
        },
        set (value) {
          this.$store.dispatch('updateStep2coapplicantphone', value)
        }
      },
      step2coapplicantdob: {
        get () {
          return this.$store.getters.step2coapplicantdob
        },
        set (value) {
          this.$store.dispatch('updateStep2coapplicantdob', value)
        }
      },
      step2coapplicantdriverslicense: {
        get () {
          return this.$store.getters.step2coapplicantdriverslicense
        },
        set (value) {
          this.$store.dispatch('updateStep2coapplicantdriverslicense', value)
        }
      },
      step2coapplicantdriverslicensestate: {
        get () {
          return this.$store.getters.step2coapplicantdriverslicensestate
        },
        set (value) {
          this.$store.dispatch('updateStep2coapplicantdriverslicensestate', value)
        }
      },
      step2coapplicantsmoking: {
        get () {
          return this.$store.getters.step2coapplicantsmoking
        },
        set (value) {
          this.$store.dispatch('updateStep2coapplicantsmoking', value)
        }
      },
      step2coapplicantemail: {
        get () {
          return this.$store.getters.step2coapplicantemail
        },
        set (value) {
          this.$store.dispatch('updateStep2coapplicantemail', value)
        }
      }
    },
    methods: {
      Previous () {
        window.scrollTo(0, 0)
        this.$router.push({name: 'Step1'})
      },
      Next () {
        let cossn2 = this.$store.getters.step2coapplicantssn2
        let cossn1 = this.$store.getters.step2coapplicantssn1
        if (cossn2 !== cossn1) {
          this.ssnCoError = 'step2coapplicantssn2'
          return false
        } else {
          this.ssnCoError = ''
        }
        let ssn2 = this.$store.getters.step2applicantssn2
        let ssn1 = this.$store.getters.step2applicantssn1
        if (ssn2 !== ssn1) {
          this.ssnError = 'step2applicantssn2'
          return false
        } else {
          this.ssnError = ''
        }
        this.$validator.validateAll().then(() => {
          if (this.errors.items.length) {
            console.log('this.errors.items.length')
            console.log(this.errors.items.length)
          } else {
            console.log('no errors')
            window.scrollTo(0, 0)
            this.$router.push({name: 'Step3'})
          }
        })
      }
    }
  }
</script>
