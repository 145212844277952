<template>
  <div id="app" class="container">
    <home></home>
    <router-view></router-view>
  </div>
</template>

<script>
import home from './components/ReturnHome'

export default {
  components: {
    home
  },
  name: 'app'
}
</script>

<style>

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
