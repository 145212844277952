<template>
  <div class="container">
    <div class="col-sm-12 col-md-12">
      <div class="container-fluid">
        <div class="panel panel-info">
          <div class="panel-body">
            {{$t('Step') }} 6 {{$t('step6title') }}
          </div>
        </div>
        <div class="panel panel-info">
          <div class="panel-heading">{{petinfo}}</div>
          <div class="panel-body">
            <div class="table-responsive">
              <table class="table table-condensed">
                <thead>
                <tr>
                  <th class="text-center"></th>
                  <th class="text-center">{{typeplaceholder}}</th>
                  <th class="text-center">{{breedplaceholder}}</th>
                  <th class="text-center">{{weightplaceholder}}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{{exampleplaceholder}}</td>
                  <td>{{dogplaceholder}}</td>
                  <td>{{dalmationplaceholder}}</td>
                  <td>{{eighteenlbsplaceholder}}</td>
                </tr>
                <tr>
                  <td>{{petplaceholder}} 1:</td>
                  <td>
                    <input
                      class="form-control"
                      :placeholder=typeplaceholder
                      v-model="pet1type"
                    >
                  </td>
                  <td>
                    <input
                      class="form-control"
                      :placeholder=breedplaceholder
                      v-model="pet1breed"
                    >
                  </td>
                  <td>
                    <input
                      class="form-control"
                      :placeholder=weightplaceholder
                      v-model="pet1weight"
                    >
                  </td>
                </tr>
                <tr>
                  <td>{{petplaceholder}} 2:</td>
                  <td>
                    <input
                      class="form-control"
                      :placeholder=typeplaceholder
                      v-model="pet2type"
                    >
                  </td>
                  <td>
                    <input
                      class="form-control"
                      :placeholder=breedplaceholder
                      v-model="pet2breed"
                    >
                  </td>
                  <td>
                    <input
                      class="form-control"
                      :placeholder=weightplaceholder
                      v-model="pet2weight"
                    >
                  </td>
                </tr>
                <tr>
                  <td>{{petplaceholder}} 3:</td>
                  <td>
                    <input
                      class="form-control"
                      :placeholder=typeplaceholder
                      v-model="pet3type"
                    >
                  </td>
                  <td>
                    <input
                      class="form-control"
                      :placeholder=breedplaceholder
                      v-model="pet3breed"
                    >
                  </td>
                  <td>
                    <input
                      class="form-control"
                      :placeholder=weightplaceholder
                      v-model="pet3weight"
                    >
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="panel panel-info">
          <div class="panel-heading">{{criminalplaceholder}}</div>
          <div class="panel-body">
            <div>
              <label for='felonies'>{{describemisdemeanorsplaceholder}}</label>
              <textarea v-model="felonies" class="form-control" :placeholder=describehereplaceholder id="felonies"
                        rows="3" style="height:auto !important;"
                        data-vv-name="felonies" v-validate="'required'"
              >

                        </textarea>
              <span style="color: darkred" v-show="errors.has('felonies')">required</span>
            </div>
            <div>
              <label for="judge" style="padding-top: 10px;">{{describeevictionsplaceholder}}</label>

              <textarea v-model="judge" class="form-control" :placeholder=describehereplaceholder id="judge" rows="3"
                        style="height:auto !important;"
                        data-vv-name="judge" v-validate="'required'"
              >

                        </textarea>
              <span style="color: darkred" v-show="errors.has('judge')">required</span>
            </div>
          </div>
        </div>
        <div class="panel panel-info">
          <div class="panel-heading">{{agreementsplaceholder}}</div>
          <div class="panel-body">
            <div class="checkbox">
              <label>
                <input type="checkbox" id="agreements1" name="agreements1" v-model="agreements1"
                       data-vv-name="agreements1" v-validate="'required'"
                >
                {{agreementwithplaceholder}}.
                <span style="color: darkred" v-show="errors.has('agreements1')">required</span>
                <div class="well">
                  {{agreementwithdescription1placeholder}}.
                </div>
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" id="agreements2" name="agreements2" v-model="agreements2"
                       data-vv-name="agreements2" v-validate="'required'">
                <span style="color: darkred" v-show="errors.has('agreements2')">required</span>
                {{agreementwithplaceholder}}.
                <div class="well">
                  {{agreementwithdescription2placeholder}}.
                </div>
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" id="agreements3" name="agreements3" v-model="agreements3" v-validate="'required'">
                <span style="color: darkred" v-show="errors.has('agreements3')">required</span>
                {{agreementwithplaceholder}}.
                <div class="well">
                  {{agreementwithdescription3placeholder}}.
                </div>
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" id="agreements4" name="agreements4" v-model="agreements4" v-validate="'required'">
                <span style="color: darkred" v-show="errors.has('agreements4')">required</span>
                {{agreementwithplaceholder}}.
                <div class="well">
                  {{agreementwithdescription4placeholder}}.
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="panel-footer">
        <button class="btn btn-success" type="submit" @click="Previous">
          {{$t('previous', {lng: this.$store.getters.language}) }}
        </button>
        <button class="btn btn-success" type="submit" @click="Next">{{$t('next', {lng: this.$store.getters.language})
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    props: ['additional'],
    data () {
      return {
        isDropdownOpen: false,
        petinfo: this.$i18n.t('step6petinfo'),
        typeplaceholder: this.$i18n.t('step6type'),
        breedplaceholder: this.$i18n.t('step6breed'),
        weightplaceholder: this.$i18n.t('step6weight'),
        exampleplaceholder: this.$i18n.t('step6example'),
        dogplaceholder: this.$i18n.t('step6dog'),
        dalmationplaceholder: this.$i18n.t('step6dalmation'),
        eighteenlbsplaceholder: this.$i18n.t('step618lbs'),
        petplaceholder: this.$i18n.t('step6pet'),
        criminalplaceholder: this.$i18n.t('step6criminaljudgementsinfo'),
        describemisdemeanorsplaceholder: this.$i18n.t('step6describemisdemeanors'),
        describehereplaceholder: this.$i18n.t('step6describehere'),
        describeevictionsplaceholder: this.$i18n.t('step6describeevictions'),
        agreementsplaceholder: this.$i18n.t('step6agreements'),
        agreementwithplaceholder: this.$i18n.t('step6agreementwith'),
        agreementwithdescription1placeholder: this.$i18n.t('step6agreementwithdescription1'),
        agreementwithdescription2placeholder: this.$i18n.t('step6agreementwithdescription2'),
        agreementwithdescription3placeholder: this.$i18n.t('step6agreementwithdescription3'),
        agreementwithdescription4placeholder: this.$i18n.t('step6agreementwithdescription4')
      }
    },
    watch: {
      '$route' (to) {
        this.language = to.query.language
      }
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.$i18n.i18next.changeLanguage(this.$store.getters.language)
      if (this.$route.query.language) {
        this.language = this.$route.query.language
      } else {
        this.language = 'en'
      }
    },
    computed: {
      agreements1: {
        get () {
          return this.$store.getters.step6agreements1
        },
        set (value) {
          this.$store.dispatch('updateStep6agreements1', value)
        }
      },
      agreements2: {
        get () {
          return this.$store.getters.step6agreements2
        },
        set (value) {
          this.$store.dispatch('updateStep6agreements2', value)
        }
      },
      agreements3: {
        get () {
          return this.$store.getters.step6agreements3
        },
        set (value) {
          this.$store.dispatch('updateStep6agreements3', value)
        }
      },
      agreements4: {
        get () {
          return this.$store.getters.step6agreements4
        },
        set (value) {
          this.$store.dispatch('updateStep6agreements4', value)
        }
      },
      pet1type: {
        get () {
          return this.$store.getters.step6pet1type
        },
        set (value) {
          this.$store.dispatch('updateStep6pet1type', value)
        }
      },
      pet1breed: {
        get () {
          return this.$store.getters.step6pet1breed
        },
        set (value) {
          this.$store.dispatch('updateStep6pet1breed', value)
        }
      },
      pet1weight: {
        get () {
          return this.$store.getters.step6pet1weight
        },
        set (value) {
          this.$store.dispatch('updateStep6pet1weight', value)
        }
      },
      pet2type: {
        get () {
          return this.$store.getters.step6pet2type
        },
        set (value) {
          this.$store.dispatch('updateStep6pet2type', value)
        }
      },
      pet2breed: {
        get () {
          return this.$store.getters.step6pet2breed
        },
        set (value) {
          this.$store.dispatch('updateStep6pet2breed', value)
        }
      },
      pet2weight: {
        get () {
          return this.$store.getters.step6pet2weight
        },
        set (value) {
          this.$store.dispatch('updateStep6pet2weight', value)
        }
      },
      pet3type: {
        get () {
          return this.$store.getters.step6pet3type
        },
        set (value) {
          this.$store.dispatch('updateStep6pet3type', value)
        }
      },
      pet3breed: {
        get () {
          return this.$store.getters.step6pet3breed
        },
        set (value) {
          this.$store.dispatch('updateStep6pet3breed', value)
        }
      },
      pet3weight: {
        get () {
          return this.$store.getters.step6pet3weight
        },
        set (value) {
          this.$store.dispatch('updateStep6pet3weight', value)
        }
      },
      felonies: {
        get () {
          return this.$store.getters.step6criminal1misdemeanors
        },
        set (value) {
          this.$store.dispatch('updateStep6criminal1misdemeanors', value)
        }
      },
      judge: {
        get () {
          return this.$store.getters.step6criminal2evictions
        },
        set (value) {
          this.$store.dispatch('updateStep6criminal2evictions', value)
        }
      }
    },
    methods: {
      Previous () {
        window.scrollTo(0, 0)
        this.$router.push({name: 'Step5'})
      },
      Next () {
        this.$validator.validateAll().then(() => {
          if (this.errors.items.length) {
            console.log('this.errors.items.length')
            console.log(this.errors.items.length)
          } else {
            console.log('no errors')
            window.scrollTo(0, 0)
            this.$router.push({name: 'Step7'})
          }
        })
      }
    }
  }
</script>
