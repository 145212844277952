// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import i18next from 'i18next'
import locales from './locales/en-US.json'
import chlocales from './locales/zh-CN.json'
import eslocales from './locales/es.json'
import store from './store/store'
import axio from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VeeValidate from 'vee-validate'
import VueI18Next from '@panter/vue-i18next'
import VueMask from 'vue-the-mask'

Vue.use(VueI18Next)
Vue.use(VeeValidate)
Vue.use(VueMask)

Vue.prototype.$http = axio

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

i18next.init({
  lng: 'en',
  resources: {
    en: { translation: locales.en },
    ch: { translation: chlocales.ch },
    es: { translation: eslocales.es }
  }
})

const i18n = new VueI18Next(i18next)
// Create VueI18n instance with options
Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  i18n,
  store,
  template: '<App/>',
  components: { App },
  render: h => h(App)
}).$mount("#app");
