<template>
  <div class="container">
    <div class="container-fluid">
      <div class="panel panel-info">
        <div class="panel-body">
          {{$t('Step', {lng: this.$store.getters.language}) }} 3 {{$t('step3title', {lng: this.$store.getters.language})
          }}
        </div>
      </div>
      <div>
        <app-current-address :address="whichapplicant" :who="Applicant"></app-current-address>
      </div>
      <div>
        <app-previous-address :address="whichapplicant" :who="Applicant"></app-previous-address>
      </div>
      <div v-if="displayCo != 'false'">
        <div>
          <current-adddress-co-applicant :address="whichcoapplicant"
                                         :who="CoApplicant"></current-adddress-co-applicant>
        </div>
        <div>
          <previous-address-co-applicant :address="whichcoapplicant"
                                         :who="CoApplicant"></previous-address-co-applicant>
        </div>
      </div>
      <div class="panel-footer">
        <button class="btn btn-success" type="submit" @click="Previous">
          {{$t('previous', {lng: this.$store.getters.language}) }}
        </button>
        <button class="btn btn-success" type="submit" @click="Next">{{$t('next', {lng: this.$store.getters.language})
          }}
        </button>
        <div class="alert alert-danger" role="alert" v-if="displayErrorMessage" variant="danger">{{errorMessage}}</div>
      </div>
    </div>
  </div>
</template>

<script>

  import CurrentAdddress from './CurrentAddress.vue'
  import CurrentAdddressCoApplicant from './CurrentAddressCoApplicant.vue'
  import PreviousAdddress from './PreviousAddress.vue'
  import PreviousAddressCoApplicant from './PreviousAddressCoApplicant.vue'

  export default {
    data () {
      return {
        displayCo: this.$store.getters.docoapp,
        isDropdownOpen: false,
        address: {},
        Applicant: '',
        errorMessage: '',
        displayErrorMessage: false,
      }
    },
    components: {
      appCurrentAddress: CurrentAdddress,
      CurrentAdddressCoApplicant,
      appPreviousAddress: PreviousAdddress,
      PreviousAddressCoApplicant
    },
    watch: {
      '$route' (to) {
        this.language = to.query.language
      }
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      if (this.$route.query.language) {
        this.language = this.$route.query.language
      } else {
        this.language = 'en'
      }
    },
    computed: {
      whichapplicant: {
        get () {
          this.$i18n.i18next.changeLanguage(this.$store.getters.language)
          let apl = this.$i18n.t('applicant')
          return {who: apl, address: {name: 'test'}}
        }
      },
      whichcoapplicant: {
        get () {
          this.$i18n.i18next.changeLanguage(this.$store.getters.language)
          let apl = this.$i18n.t('coapplicant')
          return {who: apl, coapplicant: 'true'}
        }
      }
    },
    methods: {
      Previous () {
        window.scrollTo(0, 0)
        this.$router.push({name: 'Step2'})
      },
      Next () {
        this.$validator.validateAll().then(() => {
          if (this.errors.items.length) {
            this.errorMessage = 'Fill out all required fields!'
            this.displayErrorMessage = true
          } else {
            window.scrollTo(0, 0)
            this.$router.push({name: 'Step4'})
          }
        }).catch((err) => console.log({where: 'validation failed', err}))
      }
    }
  }
</script>
