<template>
    <div>
        <app-occupant v-for="occupant in occupants" :occupant="occupant" :key="occupant.id"></app-occupant>
    </div>
</template>

<script>
  import Occupant from './Occupant.vue'

  export default {
    components: {
      appOccupant: Occupant
    },
    computed: {
      occupants () {
        return this.$store.getters.occupants
      }
    }
  }
</script>
