<template>
  <div class="col-sm-12 col-md-12">
    <div class="panel panel-info">
      <div class="panel-heading">
        <h3 class="panel-title">
          {{$t('step5reference', {lng: this.$store.getters.language}) }} {{reference.whichone}}
        </h3>
      </div>
      <div class="panel-body">
        <div class="form-grouppull-left">
          <div>
            <input
              type="hidden"
              class="form-control"
              :placeholder="id"
              v-model="id"
              :class="{danger: emptyName}"
            >
          </div>
          <div>
            <label for="namesecond"> {{nameplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="nameplaceholder"
            v-model="namesecond"
            id="namesecond"
            @input="updateName"
            data-vv-name="namesecond" v-validate="'required'"
            >
            <span style="color: darkred" v-show="errors.has('namesecond')">required</span>
          </div>
          <div>
            <label for="telephonesecond"> {{telephoneplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="telephoneplaceholder"
            v-model="telephonesecond"
            id="telephonesecond"
            @input="updateTelephone"
            data-vv-name="telephonesecond" v-validate="'required'"
            v-mask="'(###)-###-####'"
            >
            <span style="color: darkred" v-show="errors.has('telephonesecond')">required</span>
          </div>
          <div>
            <label for="relationshipsecond"> {{relationshipplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="relationshipplaceholder"
            v-model="relationshipsecond"
            id="relationshipsecond"
            @input="updateRelationship"
            data-vv-name="relationshipsecond" v-validate="'required'"
            >
            <span style="color: darkred" v-show="errors.has('relationshipsecond')">required</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .danger {
    border: 1px solid red;
  }
</style>

<script>

  export default {
    props: ['reference'],
    inject: ['$validator'],
    data () {
      return {
        id: this.reference.id,
        namesecond: this.$store.getters.step5name12,
        telephonesecond: this.$store.getters.step5telephone12,
        relationshipsecond: this.$store.getters.step5relationship12,
        nameplaceholder: this.$i18n.t('step5name'),
        telephoneplaceholder: this.$i18n.t('step5telephone'),
        relationshipplaceholder: this.$i18n.t('step5relationship')
      }
    },
    computed: {
      emptyName () {
        return this.name === ''
      }
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      if (this.$route.query.language) {
        this.language = this.$route.query.language
      } else {
        this.language = 'en'
      }
    },
    watch: {
      '$route' (to) {
        this.language = to.query.language
      }
    },
    methods: {
      updateName (event) {
        const occup = event.target.value
        this.$store.dispatch('updateStep5name12', occup)
      },
      updateRelationship (event) {
        const occup = event.target.value
        this.$store.dispatch('updateStep5relationship12', occup)
      },
      updateTelephone (event) {
        const occup = event.target.value
        this.$store.dispatch('updateStep5telephone12', occup)
      },
      updateVerifywith (event) {
        const occup = {id: this.id, name: event.target.value, relationship: this.relationship, age: this.age}
        this.$store.dispatch('updateCurrentEmployment', occup)
      }
    }
  }
</script>
