<template>
  <div class="container">
    <div class="container-fluid">
      <div class="panel panel-default">
        <div class="panel-body">
          {{$t('Step', {lng: this.$store.getters.language}) }} 9 {{$t('step9title', {lng: this.$store.getters.language})
          }}
          <h2>Receipt</h2>
          <fees-table></fees-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import FeesTable from './FeesTable.vue'
  export default {
    data () {
      return {
        isDropdownOpen: false
      }
    },
    watch: {
      '$route' (to) {
        this.language = to.query.language
      }
    },
    components: {
      FeesTable
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      if (this.$route.query.language) {
        this.language = this.$route.query.language
      } else {
        this.language = 'en'
      }
      // clear vuex store
      // this.$store.dispatch('clearAllGlobal')
    }
  }
</script>
