<template>
  <div class="col-sm-12 col-md-12">
    <div class="panel panel-info">
      <div class="panel-heading">
        <h3 class="panel-title">
          {{ address.who }}'s {{$t('step3previousaddress', {lng: this.$store.getters.language}) }}: {{ address.id }}
        </h3>
      </div>
      <div class="panel-body">
        <div class="form-grouppull-left">
          <input
            type="hidden"
            class="form-control"
            placeholder="id"
            v-model="id"
            :class="{danger: emptyName}"
          >
          <div class="checkbox">
            <label>
              <input v-model="checkednopreviousapartment" @change="checkedNoPreviousApartment($event)" type="checkbox"> {{$t('step3previousaddresscheckbox', {lng: this.$store.getters.language}) }}
            </label>
          </div>
          <div>
            <label for="previousapartmentname"> {{previousapartmentnameplaceholder}}</label>
            <input
              class="form-control"
              :placeholder="previousapartmentnameplaceholder"
              v-model="previousapartmentname"
              id="previousapartmentname"
              @input="updateApartmentName"
              :class="{danger: emptyName}"
              data-vv-name="previousapartmentname" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('previousapartmentname')">required</span>
          </div>
          <div>
            <label for="previousmanagersname"> {{managersnameplaceholder}}</label>
            <input
              class="form-control"
              :placeholder="managersnameplaceholder"
              v-model="previousmanagersname"
              id="previousmanagersname"
              @input="updateManagersname"
              data-vv-name="previousmanagersname" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('previousmanagersname')">required</span>
          </div>
          <div>
            <label for="previoustelephone"> {{telephoneplaceholder}}</label>
            <input
              class="form-control"
              :placeholder="telephoneplaceholder"
              v-model="previoustelephone"
              id="previoustelephone"
              @input="updateTelephone"
              data-vv-name="previoustelephone" v-validate="rules"
              v-mask="'(###)-###-####'"
            >
            <span style="color: darkred" v-show="errors.has('previoustelephone')">required</span>
          </div>
          <div>
            <label for="previousaddress1"> {{address1placeholder}}</label>
            <input
              class="form-control"
              :placeholder="address1placeholder"
              v-model="previousaddress1"
              id="previousaddress1"
              @input="updateAddress"
              data-vv-name="previousaddress1" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('previousaddress1')">required</span>
          </div>
          <div>
            <label for="previouscity"> {{cityplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="cityplaceholder"
            v-model="previouscity"
            id="previouscity"
            @input="updateCity"
            data-vv-name="previouscity" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('previouscity')">required</span>
          </div>
          <div>
            <label for="previousstate"> {{stateplaceholder}}</label>
            <b-form-select id="previousstate" v-model="previousstate" @change="updateState"
                           data-vv-name="previousstate" v-validate="rules"
            >
              <option v-for="(item, index) in states" @value="index" :key="index">{{item}}</option>
            </b-form-select>
            <span style="color: darkred" v-show="errors.has('previousstate')">required</span>
          </div>
          <div>
            <label for="previouszip"> {{zipplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="zipplaceholder"
            v-model="previouszip"
            id="previouszip"
            @input="updateZip"
            v-mask="'#####'"
            data-vv-name="previouszip" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('previouszip')">required</span>
          </div>
          <div>
            <label for="previousrentamount"> {{rentmortageamountplaceholder}}</label>
            <input
              class="form-control"
              :placeholder="rentmortageamountplaceholder"
              v-model="previousrentamount"
              id="previousrentamount"
              @input="updateRentAmount"
              data-vv-name="previousrentamount" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('previousrentamount')">required</span>
          </div>
          <div>
            <label for="previousmoveindate"> {{moveindateplaceholder}}</label>
            <b-row>
              <b-col>Month</b-col>
              <b-col>Year</b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-select id="previousmoveindate" v-model="previousmoveindate"
                               data-vv-name="previousmoveindate" v-validate="rules"
                               @input="updateMoveInDate"
                >
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </b-form-select>
              </b-col>
              <b-col>
                <b-form-select id="previousmoveindateyear" v-model="previousmoveindateyear"
                               data-vv-name="previousmoveindateyear" v-validate="rules"
                               @input="updateMoveInDateYear"
                >
                  <option value="0">Year:</option>
                  <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                </b-form-select>
              </b-col>
            </b-row>
            <span style="color: darkred" v-show="errors.has('previousmoveindate')">required</span>
          </div>
          <div>
            <label for="previousmoveoutdate"> {{moveoutdateplaceholder}}</label>
            <b-row>
              <b-col>Month</b-col>
              <b-col>Year</b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-select id="previousmoveoutdate" v-model="previousmoveoutdate"
                               data-vv-name="previousmoveoutdate" v-validate="rules"
                               @input="updateMoveOutDate"
                >
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </b-form-select>
              </b-col>
              <b-col>
                <b-form-select id="previousmoveoutdateyear" v-model="previousmoveoutdateyear"
                               data-vv-name="previousmoveoutdateyear" v-validate="rules"
                               @input="updateMoveOutDateYear"
                >
                  <option value="0">Year:</option>
                  <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                </b-form-select>
              </b-col>
            </b-row>
            <span style="color: darkred" v-show="errors.has('previousmoveoutdate')">required</span>
          </div>
          <div>
            <label for="previousreasonforleaving"> {{reasonforleavingplaceholder}}</label>
            <input
              class="form-control"
              :placeholder="reasonforleavingplaceholder"
              v-model="previousreasonforleaving"
              id="previousreasonforleaving"
              @input="updateReasonForLeaving"
              data-vv-name="previousreasonforleaving" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('previousreasonforleaving')">required</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .danger {
    border: 1px solid red;
  }
</style>

<script>

  export default {
    props: ['address'],
    inject: ['$validator'],
    data () {
      return {
        id: this.address.id,
        checkednopreviousapartment: this.$store.getters.step3checkednopreviousapartment,
        previousapartmentname: this.address.who === 'Applicant' ? this.$store.getters.step3previousaddressapartmentname : this.$store.getters.step3previousaddressapartmentname2,
        previousmanagersname: this.address.who === 'Applicant' ? this.$store.getters.step3previousaddressmanagersname : this.$store.getters.step3previousaddressmanagersname2,
        previoustelephone: this.address.who === 'Applicant' ? this.$store.getters.step3previousaddresstelephone : this.$store.getters.step3previousaddresstelephone2,
        previousaddress1: this.address.who === 'Applicant' ? this.$store.getters.step3previousaddressaddress1 : this.$store.getters.step3previousaddressaddress12,
        previouscity: this.address.who === 'Applicant' ? this.$store.getters.step3previousaddresscity : this.$store.getters.step3previousaddresscity2,
        previousstate: this.address.who === 'Applicant' ? this.$store.getters.step3previousaddressstate : this.$store.getters.step3previousaddressstate2,
        previouszip: this.address.who === 'Applicant' ? this.$store.getters.step3previousaddresszip : this.$store.getters.step3previousaddresszip2,
        previousrentamount: this.address.who === 'Applicant' ? this.$store.getters.step3previousaddressrentamount : this.$store.getters.step3previousaddressrentamount2,
        previousmoveindate: this.address.who === 'Applicant' ? this.$store.getters.step3previousaddressmoveindate : this.$store.getters.step3previousaddressmoveindate2,
        previousmoveindateyear: this.address.who === 'Applicant' ? this.$store.getters.step3previousaddressmoveindateyear : this.$store.getters.step3previousaddressmoveindate2year,
        previousmoveoutdate: this.address.who === 'Applicant' ? this.$store.getters.step3previousaddressmoveoutdate : this.$store.getters.step3previousaddressmoveoutdate2,
        previousmoveoutdateyear: this.address.who === 'Applicant' ? this.$store.getters.step3previousaddressmoveoutdateyear : this.$store.getters.step3previousaddressmoveoutdate2year,
        previousreasonforleaving: this.address.who === 'Applicant' ? this.$store.getters.step3previousaddressreasonforleaving : this.$store.getters.step3previousaddressreasonforleaving2,
        previousapartmentnameplaceholder: this.$i18n.t('step3previousaddresspreviousapartmentname'),
        managersnameplaceholder: this.$i18n.t('step3previousaddressmanagersname'),
        telephoneplaceholder: this.$i18n.t('step3previousaddresstelephone'),
        address1placeholder: this.$i18n.t('step3previousaddressaddress1'),
        cityplaceholder: this.$i18n.t('step3previousaddresscity'),
        stateplaceholder: this.$i18n.t('step3previousaddressstate'),
        zipplaceholder: this.$i18n.t('step3previousaddresszip'),
        rentmortageamountplaceholder: this.$i18n.t('step3previousaddressrentmortgageamount'),
        moveindateplaceholder: this.$i18n.t('step3previousaddressmoveindate'),
        moveoutdateplaceholder: this.$i18n.t('step3previousaddressmoveoutdate'),
        reasonforleavingplaceholder: this.$i18n.t('step3previousaddressreasonforleaving'),
        states: {
                  'AL': 'Alabama',
                  'AK': 'Alaska',
                  'AS': 'American Samoa',
                  'AZ': 'Arizona',
                  'AR': 'Arkansas',
                  'CA': 'California',
                  'CO': 'Colorado',
                  'CT': 'Connecticut',
                  'DE': 'Delaware',
                  'DC': 'District Of Columbia',
                  'FM': 'Federated States Of Micronesia',
                  'FL': 'Florida',
                  'GA': 'Georgia',
                  'GU': 'Guam',
                  'HI': 'Hawaii',
                  'ID': 'Idaho',
                  'IL': 'Illinois',
                  'IN': 'Indiana',
                  'IA': 'Iowa',
                  'KS': 'Kansas',
                  'KY': 'Kentucky',
                  'LA': 'Louisiana',
                  'ME': 'Maine',
                  'MH': 'Marshall Islands',
                  'MD': 'Maryland',
                  'MA': 'Massachusetts',
                  'MI': 'Michigan',
                  'MN': 'Minnesota',
                  'MS': 'Mississippi',
                  'MO': 'Missouri',
                  'MT': 'Montana',
                  'NE': 'Nebraska',
                  'NV': 'Nevada',
                  'NH': 'New Hampshire',
                  'NJ': 'New Jersey',
                  'NM': 'New Mexico',
                  'NY': 'New York',
                  'NC': 'North Carolina',
                  'ND': 'North Dakota',
                  'MP': 'Northern Mariana Islands',
                  'OH': 'Ohio',
                  'OK': 'Oklahoma',
                  'OR': 'Oregon',
                  'PW': 'Palau',
                  'PA': 'Pennsylvania',
                  'PR': 'Puerto Rico',
                  'RI': 'Rhode Island',
                  'SC': 'South Carolina',
                  'SD': 'South Dakota',
                  'TN': 'Tennessee',
                  'TX': 'Texas',
                  'UT': 'Utah',
                  'VT': 'Vermont',
                  'VI': 'Virgin Islands',
                  'VA': 'Virginia',
                  'WA': 'Washington',
                  'WV': 'West Virginia',
                  'WI': 'Wisconsin',
                  'WY': 'Wyoming'
                }
      }
    },
    computed: {
      emptyName () {
        return this.name === ''
      },
      rules () {
        console.log('rules')
        console.log(this.checkednopreviousapartment)
        return !this.checkednopreviousapartment ? 'required' : ''
      },
      years () {
        const year = new Date(new Date().setFullYear(new Date().getFullYear() + 11)).getFullYear()
        console.log('year')
        console.log(year)
        return Array.from({length: year - 1970}, (value, index) => 1970 + index)
      }
    },
    methods: {
      checkedNoPreviousApartment () {
        this.$store.dispatch('updateStep3CheckedNoPreviousApartment', this.checkednopreviousapartment)
      },
      updateTelephone (event) {
        const occup = event.target.value
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3previousaddresstelephone', occup)
        } else {
          this.$store.dispatch('updateStep3previousaddresstelephone2', occup)
        }
      },
      updateManagersname (event) {
        const occup = event.target.value
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3previousaddressmanagersname', occup)
        } else {
          this.$store.dispatch('updateStep3previousaddressmanagersname2', occup)
        }
      },
      updateApartmentName (event) {
        const occup = event.target.value
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3previousaddressapartmentname', occup)
        } else {
          this.$store.dispatch('updateStep3previousaddressapartmentname2', occup)
        }
      },
      updateAddress (event) {
        const occup = event.target.value
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3previousaddressaddress1', occup)
        } else {
          this.$store.dispatch('updateStep3previousaddressaddress12', occup)
        }
      },
      updateCity (event) {
        const occup = event.target.value
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3previousaddresscity', occup)
        } else {
          this.$store.dispatch('updateStep3previousaddresscity2', occup)
        }
      },
      updateState (event) {
        const occup = event
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3previousaddressstate', occup)
        } else {
          this.$store.dispatch('updateStep3previousaddressstate2', occup)
        }
      },
      updateZip (event) {
        const occup = event.target.value
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3previousaddresszip', occup)
        } else {
          this.$store.dispatch('updateStep3previousaddresszip2', occup)
        }
      },
      updateRentAmount (event) {
        const occup = event.target.value
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3previousaddressrentamount', occup)
        } else {
          this.$store.dispatch('updateStep3previousaddressrentamount2', occup)
        }
      },
      updateMoveInDate (event) {
        const occup = event
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3previousaddressmoveindate', occup)
        } else {
          this.$store.dispatch('updateStep3previousaddressmoveindate2', occup)
        }
      },
      updateMoveInDateYear (event) {
        const occup = event
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3previousaddressmoveindateyear', occup)
        } else {
          this.$store.dispatch('updateStep3previousaddressmoveindate2year', occup)
        }
      },
      updateMoveOutDate (event) {
        const occup = event
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3previousaddressmoveoutdate', occup)
        } else {
          this.$store.dispatch('updateStep3previousaddressmoveoutdate2', occup)
        }
      },
      updateMoveOutDateYear (event) {
        const occup = event
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3previousaddressmoveoutdateyear', occup)
        } else {
          this.$store.dispatch('updateStep3previousaddressmoveoutdate2year', occup)
        }
      },
      updateReasonForLeaving (event) {
        const occup = event.target.value
        if (this.address.who === 'Applicant') {
          this.$store.dispatch('updateStep3previousaddressreasonforleaving', occup)
        } else {
          this.$store.dispatch('updateStep3previousaddressreasonforleaving2', occup)
        }
      }
    }
  }
</script>
