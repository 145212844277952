<template>
  <div class="container">
    <div class="container-fluid">
      <div class="panel panel-info">
        <div class="panel-body">
          {{$t('Step', {lng: this.$store.getters.language}) }} 8 {{$t('step8title', {lng: this.$store.getters.language})
          }}
        </div>
      </div>
      <div class="panel panel-info">
        <div class="panel-heading">{{step7creditcardinfoplaceholder}}</div>
        <div class="panel-body">
          <fees-table></fees-table>
          {{step7firstnameplaceholder}}
          <input
            type="text"
            class="form-control"
            :value=step7firstname
            disabled
          >
          {{step7lastnameplaceholder}}
          <input
            type="text"
            class="form-control"
            :value=step7lastname
            disabled
          >
          {{step7nameshownoncardplaceholder}}
          <input
            type="text"
            class="form-control"
            :value=step7nameshownoncard
            disabled
          >
          {{step7emailaddressplaceholder}}
          <input
            type="text"
            class="form-control"
            :value=step7emailaddress
            disabled
          >
          {{step7addressplaceholder}}
          <input
            type="text"
            class="form-control"
            :value=step7address
            disabled
          >
          {{step7cityplaceholder}}
          <input
            type="text"
            class="form-control"
            :value=step7city
            disabled
          >
          {{step7stateplaceholder}}
          <input
            type="text"
            class="form-control"
            :value=step7state
            disabled
          >
          {{step7zipplaceholder}}
          <input
            type="text"
            class="form-control"
            :value=step7zip
            disabled
          >
          {{step7creditcardtypeplaceholder}}
          <input
            type="text"
            class="form-control"
            :value=step7creditcardtype
            disabled
          >
          {{step7creditcardnumberplaceholder}}
          <input
            type="text"
            class="form-control"
            :value=step7creditcardnumber
            disabled
          >
          {{step7expirationdatecreditcardplaceholder}}
          <input
            type="text"
            class="form-control"
            :value="step7expirationdatecreditcard + '/' + step7expirationdatecreditcardyear"
            disabled
          >
          {{step7cviplaceholder}}
          <input
            type="text"
            class="form-control"
            :value=step7cvi
            disabled
          >
        </div>
      </div>
    </div>
    <div class="panel-footer">
      <rotate-square2 v-if="loading"></rotate-square2>
      <button class="btn btn-success" type="submit" @click="Previous">
        {{$t('previous', {lng: this.$store.getters.language}) }}
      </button>
      <button class="btn btn-success" type="submit" @click="Next">{{$t('placeorder', {lng:
        this.$store.getters.language}) }}
      </button>
      <div class="alert alert-danger" role="alert" v-if="displayErrorMessage" variant="danger">{{errorMessage}}</div>
    </div>
  </div>
</template>

<script>
  import {RotateSquare2} from 'vue-loading-spinner'
  import FeesTable from './FeesTable.vue'
  export default {
    data () {
      return {
        loading: false,
        isDropdownOpen: false,
        errorMessage: '',
        displayErrorMessage: false,
        step7creditcardinfoplaceholder: this.$i18n.t('step7creditcardinfo'),
        step7firstnameplaceholder: this.$i18n.t('step7firstname'),
        step7lastnameplaceholder: this.$i18n.t('step7lastname'),
        step7nameshownoncardplaceholder: this.$i18n.t('step7nameshownoncard'),
        step7emailaddressplaceholder: this.$i18n.t('step7emailaddress'),
        step7addressplaceholder: this.$i18n.t('step7address'),
        step7cityplaceholder: this.$i18n.t('step7city'),
        step7stateplaceholder: this.$i18n.t('step7state'),
        step7zipplaceholder: this.$i18n.t('step7zip'),
        step7creditcardtypeplaceholder: this.$i18n.t('step7creditcardtype'),
        step7creditcardnumberplaceholder: this.$i18n.t('step7creditcardnumber'),
        step7expirationdatecreditcardplaceholder: this.$i18n.t('step7expirationdatecreditcard'),
        step7cviplaceholder: this.$i18n.t('step7cvi')
      }
    },
    watch: {
      '$route' (to) {
        this.language = to.query.language
      }
    },
    components: {
      RotateSquare2, FeesTable
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      if (this.$route.query.language) {
        this.language = this.$route.query.language
      } else {
        this.language = 'en'
      }
    },
    computed: {
      step7firstname: {
        get () {
          return this.$store.getters.step7firstname
        },
        set (value) {
          this.$store.dispatch('updateStep7firstname', value)
        }
      },
      step7lastname: {
        get () {
          return this.$store.getters.step7lastname
        },
        set (value) {
          this.$store.dispatch('updateStep7lastname', value)
        }
      },
      step7nameshownoncard: {
        get () {
          return this.$store.getters.step7nameshownoncard
        },
        set (value) {
          this.$store.dispatch('updateStep7nameshownoncard', value)
        }
      },
      step7emailaddress: {
        get () {
          return this.$store.getters.step7emailaddress
        },
        set (value) {
          this.$store.dispatch('updateStep7emailaddress', value)
        }
      },
      step7address: {
        get () {
          return this.$store.getters.step7address
        },
        set (value) {
          this.$store.dispatch('updateStep7address', value)
        }
      },
      step7city: {
        get () {
          return this.$store.getters.step7city
        },
        set (value) {
          this.$store.dispatch('updateStep7city', value)
        }
      },
      step7state: {
        get () {
          return this.$store.getters.step7state
        },
        set (value) {
          this.$store.dispatch('updateStep7state', value)
        }
      },
      step7zip: {
        get () {
          return this.$store.getters.step7zip
        },
        set (value) {
          this.$store.dispatch('updateStep7zip', value)
        }
      },
      step7creditcardtype: {
        get () {
          return this.$store.getters.step7creditcardtype
        },
        set (value) {
          this.$store.dispatch('updateStep7creditcardtype', value)
        }
      },
      step7creditcardnumber: {
        get () {
          return this.$store.getters.step7creditcardnumber
        },
        set (value) {
          this.$store.dispatch('updateStep7creditcardnumber', value)
        }
      },
      step7expirationdatecreditcard: {
        get () {
          return this.$store.getters.step7expirationdatecreditcard
        },
        set (value) {
          this.$store.dispatch('updateStep7expirationdatecreditcard', value)
        }
      },
      step7expirationdatecreditcardyear: {
        get () {
          return this.$store.getters.step7expirationdatecreditcardyear
        },
        set (value) {
          this.$store.dispatch('updateStep7expirationdatecreditcardyear', value)
        }
      },
      step7cvi: {
        get () {
          return this.$store.getters.step7cvi
        },
        set (value) {
          this.$store.dispatch('updateStep7cvi', value)
        }
      }
    },
    methods: {
      Previous () {
        window.scrollTo(0, 0)
        this.$router.push({name: 'Step7'})
      },
      Next () {
        this.$validator.validateAll().then(() => {
          console.log('result')
          if (this.errors.items.length) {
            console.log('this.errors.items.length')
            console.log(this.errors.items.length)
          } else {
            console.log('vuex store getters')

            let payloadPre = this.$store.getters
            payloadPre.amount = this.$store.getters.getTotalCharge
            const payload = JSON.stringify(payloadPre)
            console.log(payload)
            let url = 'https://bhdri75dec.execute-api.us-east-1.amazonaws.com/dev/orapplication'
            this.loading = true
            this.$http.post(url, {application: payload}).then(data => {
              console.log(data)
              this.loading = false
              if (data && data.data && data.data.statusCode && data.data.statusCode === 400 || data.data.errorMessage) {
                this.errorMessage = 'There has been an error in processing your credit card. Please check the submitted information and try again. ' + data.data.body
                this.displayErrorMessage = true
              } else {
                window.scrollTo(0, 0)
                this.$router.push({name: 'Step9'})
              }
            }).catch((e) => {
              console.log('Caught', e)
              this.loading = false
            })
          }
        })
      }
    }
  }
</script>
