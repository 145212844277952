const state = {
  language: 'en',
  comments: '',
  desiredapartment: '',
  numberoccupants: '',
  complexid: '',
  dorental: '',
  doemployment: '',
  docoapp: '',
  doevictions: '',
  evictionstate: '',
  coevictionstate: '',
  complexname: '',
  complexapplicationfee: '',
  raapplicationfee: '',
  adultapplicationfee: '',
  employmentreportprice: '',
  rentalreportprice: '',
  evictionprice: '',
  backgroundcreditreportcost: '',
  occupants: [],
  moveindate: '',
  step2applicantfirstname: '',
  step2applicantlastname: '',
  step2applicantssn1: '',
  step2applicantssn2: '',
  step2applicantdob: '',
  step2applicantphone: '',
  step2applicantdriverslicense: '',
  step2applicantdriverslicensestate: '',
  step2applicantsmoking: '',
  step2applicantemail: '',
  step2coapplicantfirstname: '',
  step2coapplicantlastname: '',
  step2coapplicantssn1: '',
  step2coapplicantssn2: '',
  step2coapplicantdob: '',
  step2coapplicantphone: '',
  step2coapplicantdriverslicense: '',
  step2coapplicantdriverslicensestate: '',
  step2coapplicantsmoking: '',
  step2coapplicantemail: '',
  step3currentaddresscurrentapartmentname: '',
  step3currentaddressmanagersname: '',
  step3currentaddresstelephone: '',
  step3currentaddressaddress1: '',
  step3currentaddresscity: '',
  step3currentaddressstate: '',
  step3currentaddresszip: '',
  step3currentaddressmoveindate: '',
  step3currentaddressmoveindateyear: '',
  step3currentaddressreasonforleaving: '',
  step3currentaddressrentamount: '',
  step3currentaddresscurrentapartmentcheckedsameasapplicant: '',
  step3checkednopreviousapartment: '',
  step3checkednopreviousapartmentcoapplicant: '',
  step3currentaddresscurrentapartmentname2: '',
  step3currentaddressmanagersname2: '',
  step3currentaddresstelephone2: '',
  step3currentaddressaddress12: '',
  step3currentaddresscity2: '',
  step3currentaddressstate2: '',
  step3currentaddresszip2: '',
  step3currentaddressmoveindate2: '',
  step3currentaddressmoveindate2year: '',
  step3currentaddressreasonforleaving2: '',
  step3currentaddressrentamount2: '',
  step3previousaddressapartmentname: '',
  step3previousaddressmanagersname: '',
  step3previousaddresstelephone: '',
  step3previousaddressaddress1: '',
  step3previousaddresscity: '',
  step3previousaddressstate: '',
  step3previousaddresszip: '',
  step3previousaddressrentamount: '',
  step3previousaddressmoveindate: '',
  step3previousaddressmoveindateyear: '',
  step3previousaddressmoveoutdate: '',
  step3previousaddressmoveoutdateyear: '',
  step3previousaddressreasonforleaving: '',
  step3previousaddressapartmentname2: '',
  step3previousaddressmanagersname2: '',
  step3previousaddresstelephone2: '',
  step3previousaddressaddress12: '',
  step3previousaddresscity2: '',
  step3previousaddressstate2: '',
  step3previousaddresszip2: '',
  step3previousaddressrentamount2: '',
  step3previousaddressmoveindate2: '',
  step3previousaddressmoveindate2year: '',
  step3previousaddressmoveoutdate2: '',
  step3previousaddressmoveoutdate2year: '',
  step3previousaddressreasonforleaving2: '',
  step4notcurrentlyemployed: '',
  step4notcurrentlyemployedcoapplicant: '',
  step4currentemploymentcompanyname: '',
  step4currentemploymentcompanyname2: '',
  step4currentemploymentsupervisorsname: '',
  step4currentemploymentsupervisorsname2: '',
  step4currentemploymenttelephone: '',
  step4currentemploymenttelephone2: '',
  step4currentemploymentaddress1: '',
  step4currentemploymentaddress12: '',
  step4currentemploymentcity: '',
  step4currentemploymentcity2: '',
  step4currentemploymentstate: '',
  step4currentemploymentstate2: '',
  step4currentemploymentzip: '',
  step4currentemploymentzip2: '',
  step4currentemploymentsalary: '',
  step4currentemploymentsalary2: '',
  step4currentemploymenthoursperweek: '',
  step4currentemploymenthoursperweek2: '',
  step4currentemploymentstartdate: '',
  step4currentemploymentstartdateyear: '',
  step4currentemploymentstartdate2: '',
  step4currentemploymentstartdate2year: '',
  step4currentemploymentenddate: '',
  step4currentemploymentenddateyear: '',
  step4currentemploymentenddate2: '',
  step4currentemploymentenddate2year: '',
  step4currentemploymentreasonforleaving: '',
  step4currentemploymentreasonforleaving2: '',
  step4additionalincomeincomesource: '',
  step4additionalincomeincomesource2: '',
  step4additionalincomeamount: '',
  step4additionalincomeamount2: '',
  step4additionalincometelephone: '',
  step4additionalincometelephone2: '',
  step4additionalincomeverifywith: '',
  step4additionalincomeverifywith2: '',
  step5name1: '',
  step5name12: '',
  step5telephone1: '',
  step5telephone12: '',
  step5relationship1: '',
  step5relationship12: '',
  step6pet1type: '',
  step6pet1breed: '',
  step6pet1weight: '',
  step6pet2type: '',
  step6pet2breed: '',
  step6pet2weight: '',
  step6pet3type: '',
  step6pet3breed: '',
  step6pet3weight: '',
  step6criminal1misdemeanors: '',
  step6criminal2evictions: '',
  step6agreements1: '',
  step6agreements2: '',
  step6agreements3: '',
  step6agreements4: '',
  step7firstname: '',
  step7lastname: '',
  step7nameshownoncard: '',
  step7emailaddress: '',
  step7address: '',
  step7city: '',
  step7state: '',
  step7zip: '',
  step7creditcardtype: '',
  step7creditcardnumber: '',
  step7expirationdatecreditcard: '',
  step7expirationdatecreditcardyear: '',
  step7cvi: '',
  usa_states: {
    'AL': 'Alabama',
    'AK': 'Alaska',
    'AS': 'American Samoa',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'DC': 'District Of Columbia',
    'FM': 'Federated States Of Micronesia',
    'FL': 'Florida',
    'GA': 'Georgia',
    'GU': 'Guam',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    'MH': 'Marshall Islands',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    'MP': 'Northern Mariana Islands',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'PW': 'Palau',
    'PA': 'Pennsylvania',
    'PR': 'Puerto Rico',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VI': 'Virgin Islands',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming'
  }
}

const mutations = {
  'LANGUAGE' (state, language) {
    state.language = language
  },
  'MOVEINDATE' (state, moveindate) {
    state.moveindate = moveindate
  },
  'COMMENTS' (state, comments) {
    state.comments = comments
  },
  'DESIREDAPARTMENT' (state, desiredapartment) {
    state.desiredapartment = desiredapartment
  },
  'NUMBEROCCUPANTS' (state, numberoccupants) {
    state.numberoccupants = numberoccupants
  },
  'COMPLEXID' (state, complexid) {
    state.complexid = complexid
  },
  'DORENTAL' (state, dorental) {
    state.dorental = dorental
  },
  'DOEMPLOYMENT' (state, doemployment) {
    state.doemployment = doemployment
  },
  'COAPP' (state, docoapp) {
    state.docoapp = docoapp
  },
  'DOEVICTIONS' (state, doevictions) {
    state.doevictions = doevictions
  },
  'EVICTIONSTATE' (state, evictionstate) {
    state.evictionstate = evictionstate
  },
  'COEVICTIONSTATE' (state, coevictionstate) {
    state.coevictionstate = coevictionstate
  },
  'COMPLEXNAME' (state, complexname) {
    state.complexname = complexname
  },
  'COMPLEXAPPLICATIONFEE' (state, complexapplicationfee) {
    state.complexapplicationfee = complexapplicationfee
  },
  'RAAPPLICATIONFEE' (state, raapplicationfee) {
    state.raapplicationfee = raapplicationfee
  },
  'ADULTAPPLICATIONFEE' (state, adultapplicationfee) {
    state.adultapplicationfee = adultapplicationfee
  },
  'EMPLOYMENTREPORTPRICE' (state, employmentreportprice) {
    state.employmentreportprice = employmentreportprice
  },
  'RENTALREPORTPRICE' (state, rentalreportprice) {
    state.rentalreportprice = rentalreportprice
  },
  'EVICTIONPRICE' (state, evictionprice) {
    state.evictionprice = evictionprice
  },
  'BACKGROUNDCREDITREPORTCOST' (state, backgroundcreditreportcost) {
    state.backgroundcreditreportcost = backgroundcreditreportcost
  },
  'SET_OCCUPANTS' (state, occupants) {
    state.occupants = occupants
  },
  'UPDATE_OCCUPANT' (state, occupant) {
    for (var i = 0; i < state.occupants.length; i++) {
      if (state.occupants[i].id === occupant.id) {
        state.occupants[i] = occupant
      }
    }
  },
  'STEP2APPLICANTFIRSTNAME' (state, step2applicantfirstname) {
    state.step2applicantfirstname = step2applicantfirstname
  },
  'STEP2APPLICANTLASTNAME' (state, step2applicantlastname) {
    state.step2applicantlastname = step2applicantlastname
  },
  'STEP2APPLICANTSSN1' (state, step2applicantssn1) {
    state.step2applicantssn1 = step2applicantssn1
  },
  'STEP2APPLICANTSSN2' (state, step2applicantssn2) {
    state.step2applicantssn2 = step2applicantssn2
  },
  'STEP2APPLICANTDOB' (state, step2applicantdob) {
    state.step2applicantdob = step2applicantdob
  },
  'STEP2APPLICANTPHONE' (state, step2applicantphone) {
    state.step2applicantphone = step2applicantphone
  },
  'STEP2APPLICANTDRIVERSLICENSE' (state, step2applicantdriverslicense) {
    state.step2applicantdriverslicense = step2applicantdriverslicense
  },
  'STEP2APPLICANTDRIVERSLICENSESTATE' (state, step2applicantdriverslicensestate) {
    state.step2applicantdriverslicensestate = step2applicantdriverslicensestate
  },
  'STEP2APPLICANTSMOKING' (state, step2applicantsmoking) {
    state.step2applicantsmoking = step2applicantsmoking
  },
  'STEP2APPLICANTEMAIL' (state, step2applicantemail) {
    state.step2applicantemail = step2applicantemail
  },
  'STEP2COAPPLICANTFIRSTNAME' (state, step2coapplicantfirstname) {
    state.step2coapplicantfirstname = step2coapplicantfirstname
  },
  'STEP2COAPPLICANTLASTNAME' (state, step2coapplicantlastname) {
    state.step2coapplicantlastname = step2coapplicantlastname
  },
  'STEP2COAPPLICANTSSN1' (state, step2coapplicantssn1) {
    state.step2coapplicantssn1 = step2coapplicantssn1
  },
  'STEP2COAPPLICANTSSN2' (state, step2coapplicantssn2) {
    state.step2coapplicantssn2 = step2coapplicantssn2
  },
  'STEP2COAPPLICANTDOB' (state, step2coapplicantdob) {
    state.step2coapplicantdob = step2coapplicantdob
  },
  'STEP2COAPPLICANTPHONE' (state, step2coapplicantphone) {
    state.step2coapplicantphone = step2coapplicantphone
  },
  'STEP2COAPPLICANTDRIVERSLICENSE' (state, step2coapplicantdriverslicense) {
    state.step2coapplicantdriverslicense = step2coapplicantdriverslicense
  },
  'STEP2COAPPLICANTDRIVERSLICENSESTATE' (state, step2coapplicantdriverslicensestate) {
    state.step2coapplicantdriverslicensestate = step2coapplicantdriverslicensestate
  },
  'STEP2COAPPLICANTSMOKING' (state, step2coapplicantsmoking) {
    state.step2coapplicantsmoking = step2coapplicantsmoking
  },
  'STEP2COAPPLICANTEMAIL' (state, step2coapplicantemail) {
    state.step2coapplicantemail = step2coapplicantemail
  },
  'STEP3CURRENTADDRESSCURRENTAPARTMENTNAME' (state, step3currentaddresscurrentapartmentname) {
    state.step3currentaddresscurrentapartmentname = step3currentaddresscurrentapartmentname
  },
  'STEP3CURRENTADDRESSMANAGERSNAME' (state, step3currentaddressmanagersname) {
    state.step3currentaddressmanagersname = step3currentaddressmanagersname
  },
  'STEP3CURRENTADDRESSTELEPHONE' (state, step3currentaddresstelephone) {
    state.step3currentaddresstelephone = step3currentaddresstelephone
  },
  'STEP3CURRENTADDRESSADDRESS1' (state, step3currentaddressaddress1) {
    state.step3currentaddressaddress1 = step3currentaddressaddress1
  },
  'STEP3CURRENTADDRESSCITY' (state, step3currentaddresscity) {
    state.step3currentaddresscity = step3currentaddresscity
  },
  'STEP3CURRENTADDRESSSTATE' (state, step3currentaddressstate) {
    state.step3currentaddressstate = step3currentaddressstate
  },
  'STEP3CURRENTADDRESSZIP' (state, step3currentaddresszip) {
    state.step3currentaddresszip = step3currentaddresszip
  },
  'STEP3CURRENTADDRESSMOVEINDATE' (state, step3currentaddressmoveindate) {
    state.step3currentaddressmoveindate = step3currentaddressmoveindate
  },
  'STEP3CURRENTADDRESSMOVEINDATEYEAR' (state, step3currentaddressmoveindateyear) {
    state.step3currentaddressmoveindateyear = step3currentaddressmoveindateyear
  },
  'STEP3CURRENTADDRESSREASONFORLEAVING' (state, step3currentaddressreasonforleaving) {
    state.step3currentaddressreasonforleaving = step3currentaddressreasonforleaving
  },
  'STEP3CURRENTADDRESSRENTAMOUNT' (state, step3currentaddressrentamount) {
    state.step3currentaddressrentamount = step3currentaddressrentamount
  },
  'STEP3CURRENTADDRESSCURRENTAPARTMENTCHECKEDSAMEASAPPLICANT' (state, step3currentaddresscurrentapartmentcheckedsameasapplicant) {
    state.step3currentaddresscurrentapartmentcheckedsameasapplicant = step3currentaddresscurrentapartmentcheckedsameasapplicant
  },
  'STEP3CHECKEDNOPREVIOUSAPARTMENT' (state, step3checkednopreviousapartment) {
    state.step3checkednopreviousapartment = step3checkednopreviousapartment
  },
  'STEP3CHECKEDNOPREVIOUSAPARTMENTCOAPPLICANT' (state, step3checkednopreviousapartmentcoapplicant) {
    state.step3checkednopreviousapartmentcoapplicant = step3checkednopreviousapartmentcoapplicant
  },
  'STEP3CURRENTADDRESSCURRENTAPARTMENTNAME2' (state, step3currentaddresscurrentapartmentname2) {
    state.step3currentaddresscurrentapartmentname2 = step3currentaddresscurrentapartmentname2
  },
  'STEP3CURRENTADDRESSMANAGERSNAME2' (state, step3currentaddressmanagersname2) {
    state.step3currentaddressmanagersname2 = step3currentaddressmanagersname2
  },
  'STEP3CURRENTADDRESSTELEPHONE2' (state, step3currentaddresstelephone2) {
    state.step3currentaddresstelephone2 = step3currentaddresstelephone2
  },
  'STEP3CURRENTADDRESSADDRESS12' (state, step3currentaddressaddress12) {
    state.step3currentaddressaddress12 = step3currentaddressaddress12
  },
  'STEP3CURRENTADDRESSCITY2' (state, step3currentaddresscity2) {
    state.step3currentaddresscity2 = step3currentaddresscity2
  },
  'STEP3CURRENTADDRESSSTATE2' (state, step3currentaddressstate2) {
    state.step3currentaddressstate2 = step3currentaddressstate2
  },
  'STEP3CURRENTADDRESSZIP2' (state, step3currentaddresszip2) {
    state.step3currentaddresszip2 = step3currentaddresszip2
  },
  'STEP3CURRENTADDRESSMOVEINDATE2' (state, step3currentaddressmoveindate2) {
    state.step3currentaddressmoveindate2 = step3currentaddressmoveindate2
  },
  'STEP3CURRENTADDRESSMOVEINDATE2YEAR' (state, step3currentaddressmoveindate2year) {
    state.step3currentaddressmoveindate2year = step3currentaddressmoveindate2year
  },
  'STEP3CURRENTADDRESSREASONFORLEAVING2' (state, step3currentaddressreasonforleaving2) {
    state.step3currentaddressreasonforleaving2 = step3currentaddressreasonforleaving2
  },
  'STEP3CURRENTADDRESSRENTAMOUNT2' (state, step3currentaddressrentamount2) {
    state.step3currentaddressrentamount2 = step3currentaddressrentamount2
  },
  'STEP3PREVIOUSADDRESSAPARTMENTNAME' (state, step3previousaddressapartmentname) {
    state.step3previousaddressapartmentname = step3previousaddressapartmentname
  },
  'STEP3PREVIOUSADDRESSMANAGERSNAME' (state, step3previousaddressmanagersname) {
    state.step3previousaddressmanagersname = step3previousaddressmanagersname
  },
  'STEP3PREVIOUSADDRESSTELEPHONE' (state, step3previousaddresstelephone) {
    state.step3previousaddresstelephone = step3previousaddresstelephone
  },
  'STEP3PREVIOUSADDRESSADDRESS1' (state, step3previousaddressaddress1) {
    state.step3previousaddressaddress1 = step3previousaddressaddress1
  },
  'STEP3PREVIOUSADDRESSCITY' (state, step3previousaddresscity) {
    state.step3previousaddresscity = step3previousaddresscity
  },
  'STEP3PREVIOUSADDRESSSTATE' (state, step3previousaddressstate) {
    state.step3previousaddressstate = step3previousaddressstate
  },
  'STEP3PREVIOUSADDRESSZIP' (state, step3previousaddresszip) {
    state.step3previousaddresszip = step3previousaddresszip
  },
  'STEP3PREVIOUSADDRESSRENTAMOUNT' (state, step3previousaddressrentamount) {
    state.step3previousaddressrentamount = step3previousaddressrentamount
  },
  'STEP3PREVIOUSADDRESSMOVEINDATE' (state, step3previousaddressmoveindate) {
    state.step3previousaddressmoveindate = step3previousaddressmoveindate
  },
  'STEP3PREVIOUSADDRESSMOVEINDATEYEAR' (state, step3previousaddressmoveindateyear) {
    state.step3previousaddressmoveindateyear = step3previousaddressmoveindateyear
  },
  'STEP3PREVIOUSADDRESSMOVEOUTDATE' (state, step3previousaddressmoveoutdate) {
    state.step3previousaddressmoveoutdate = step3previousaddressmoveoutdate
  },
  'STEP3PREVIOUSADDRESSMOVEOUTDATEYEAR' (state, step3previousaddressmoveoutdateyear) {
    state.step3previousaddressmoveoutdateyear = step3previousaddressmoveoutdateyear
  },
  'STEP3PREVIOUSADDRESSREASONFORLEAVING' (state, step3previousaddressreasonforleaving) {
    state.step3previousaddressreasonforleaving = step3previousaddressreasonforleaving
  },
  'STEP3PREVIOUSADDRESSAPARTMENTNAME2' (state, step3previousaddressapartmentname2) {
    state.step3previousaddressapartmentname2 = step3previousaddressapartmentname2
  },
  'STEP3PREVIOUSADDRESSMANAGERSNAME2' (state, step3previousaddressmanagersname2) {
    state.step3previousaddressmanagersname2 = step3previousaddressmanagersname2
  },
  'STEP3PREVIOUSADDRESSTELEPHONE2' (state, step3previousaddresstelephone2) {
    state.step3previousaddresstelephone2 = step3previousaddresstelephone2
  },
  'STEP3PREVIOUSADDRESSADDRESS12' (state, step3previousaddressaddress12) {
    state.step3previousaddressaddress12 = step3previousaddressaddress12
  },
  'STEP3PREVIOUSADDRESSCITY2' (state, step3previousaddresscity2) {
    state.step3previousaddresscity2 = step3previousaddresscity2
  },
  'STEP3PREVIOUSADDRESSSTATE2' (state, step3previousaddressstate2) {
    state.step3previousaddressstate2 = step3previousaddressstate2
  },
  'STEP3PREVIOUSADDRESSZIP2' (state, step3previousaddresszip2) {
    state.step3previousaddresszip2 = step3previousaddresszip2
  },
  'STEP3PREVIOUSADDRESSRENTAMOUNT2' (state, step3previousaddressrentamount2) {
    state.step3previousaddressrentamount2 = step3previousaddressrentamount2
  },
  'STEP3PREVIOUSADDRESSMOVEINDATE2' (state, step3previousaddressmoveindate2) {
    state.step3previousaddressmoveindate2 = step3previousaddressmoveindate2
  },
  'STEP3PREVIOUSADDRESSMOVEINDATE2YEAR' (state, step3previousaddressmoveindate2year) {
    state.step3previousaddressmoveindate2year = step3previousaddressmoveindate2year
  },
  'STEP3PREVIOUSADDRESSMOVEOUTDATE2' (state, step3previousaddressmoveoutdate2) {
    state.step3previousaddressmoveoutdate2 = step3previousaddressmoveoutdate2
  },
  'STEP3PREVIOUSADDRESSMOVEOUTDATE2YEAR' (state, step3previousaddressmoveoutdate2year) {
    state.step3previousaddressmoveoutdate2year = step3previousaddressmoveoutdate2year
  },
  'STEP3PREVIOUSADDRESSREASONFORLEAVING2' (state, step3previousaddressreasonforleaving2) {
    state.step3previousaddressreasonforleaving2 = step3previousaddressreasonforleaving2
  },
  'STEP4NOTCURRENTLYEMPLOYED' (state, step4notcurrentlyemployed) {
    state.step4notcurrentlyemployed = step4notcurrentlyemployed
  },
  'STEP4NOTCURRENTLYEMPLOYEDCOAPPLICANT' (state, step4notcurrentlyemployedcoapplicant) {
    state.step4notcurrentlyemployedcoapplicant = step4notcurrentlyemployedcoapplicant
  },
  'STEP4CURRENTEMPLOYMENTCOMPANYNAME' (state, step4currentemploymentcompanyname) {
    state.step4currentemploymentcompanyname = step4currentemploymentcompanyname
  },
  'STEP4CURRENTEMPLOYMENTCOMPANYNAME2' (state, step4currentemploymentcompanyname2) {
    state.step4currentemploymentcompanyname2 = step4currentemploymentcompanyname2
  },
  'STEP4CURRENTEMPLOYMENTSUPERVISORSNAME' (state, step4currentemploymentsupervisorsname) {
    state.step4currentemploymentsupervisorsname = step4currentemploymentsupervisorsname
  },
  'STEP4CURRENTEMPLOYMENTSUPERVISORSNAME2' (state, step4currentemploymentsupervisorsname2) {
    state.step4currentemploymentsupervisorsname2 = step4currentemploymentsupervisorsname2
  },
  'STEP4CURRENTEMPLOYMENTTELEPHONE' (state, step4currentemploymenttelephone) {
    state.step4currentemploymenttelephone = step4currentemploymenttelephone
  },
  'STEP4CURRENTEMPLOYMENTTELEPHONE2' (state, step4currentemploymenttelephone2) {
    state.step4currentemploymenttelephone2 = step4currentemploymenttelephone2
  },
  'STEP4CURRENTEMPLOYMENTADDRESS1' (state, step4currentemploymentaddress1) {
    state.step4currentemploymentaddress1 = step4currentemploymentaddress1
  },
  'STEP4CURRENTEMPLOYMENTADDRESS12' (state, step4currentemploymentaddress12) {
    state.step4currentemploymentaddress12 = step4currentemploymentaddress12
  },
  'STEP4CURRENTEMPLOYMENTCITY' (state, step4currentemploymentcity) {
    state.step4currentemploymentcity = step4currentemploymentcity
  },
  'STEP4CURRENTEMPLOYMENTCITY2' (state, step4currentemploymentcity2) {
    state.step4currentemploymentcity2 = step4currentemploymentcity2
  },
  'STEP4CURRENTEMPLOYMENTSTATE' (state, step4currentemploymentstate) {
    state.step4currentemploymentstate = step4currentemploymentstate
  },
  'STEP4CURRENTEMPLOYMENTSTATE2' (state, step4currentemploymentstate2) {
    state.step4currentemploymentstate2 = step4currentemploymentstate2
  },
  'STEP4CURRENTEMPLOYMENTZIP' (state, step4currentemploymentzip) {
    state.step4currentemploymentzip = step4currentemploymentzip
  },
  'STEP4CURRENTEMPLOYMENTZIP2' (state, step4currentemploymentzip2) {
    state.step4currentemploymentzip2 = step4currentemploymentzip2
  },
  'STEP4CURRENTEMPLOYMENTSALARY' (state, step4currentemploymentsalary) {
    state.step4currentemploymentsalary = step4currentemploymentsalary
  },
  'STEP4CURRENTEMPLOYMENTSALARY2' (state, step4currentemploymentsalary2) {
    state.step4currentemploymentsalary2 = step4currentemploymentsalary2
  },
  'STEP4CURRENTEMPLOYMENTHOURSPERWEEK' (state, step4currentemploymenthoursperweek) {
    state.step4currentemploymenthoursperweek = step4currentemploymenthoursperweek
  },
  'STEP4CURRENTEMPLOYMENTHOURSPERWEEK2' (state, step4currentemploymenthoursperweek2) {
    state.step4currentemploymenthoursperweek2 = step4currentemploymenthoursperweek2
  },
  'STEP4CURRENTEMPLOYMENTSTARTDATE' (state, step4currentemploymentstartdate) {
    state.step4currentemploymentstartdate = step4currentemploymentstartdate
  },
  'STEP4CURRENTEMPLOYMENTSTARTDATEYEAR' (state, step4currentemploymentstartdateyear) {
    state.step4currentemploymentstartdateyear = step4currentemploymentstartdateyear
  },
  'STEP4CURRENTEMPLOYMENTSTARTDATE2' (state, step4currentemploymentstartdate2) {
    state.step4currentemploymentstartdate2 = step4currentemploymentstartdate2
  },
  'STEP4CURRENTEMPLOYMENTSTARTDATE2YEAR' (state, step4currentemploymentstartdate2year) {
    state.step4currentemploymentstartdate2year = step4currentemploymentstartdate2year
  },
  'STEP4CURRENTEMPLOYMENTENDDATE' (state, step4currentemploymentenddate) {
    state.step4currentemploymentenddate = step4currentemploymentenddate
  },
  'STEP4CURRENTEMPLOYMENTENDDATEYEAR' (state, step4currentemploymentenddateyear) {
    state.step4currentemploymentenddateyear = step4currentemploymentenddateyear
  },
  'STEP4CURRENTEMPLOYMENTENDDATE2' (state, step4currentemploymentenddate2) {
    state.step4currentemploymentenddate2 = step4currentemploymentenddate2
  },
  'STEP4CURRENTEMPLOYMENTENDDATE2YEAR' (state, step4currentemploymentenddate2year) {
    state.step4currentemploymentenddate2year = step4currentemploymentenddate2year
  },
  'STEP4CURRENTEMPLOYMENTREASONFORLEAVING' (state, step4currentemploymentreasonforleaving) {
    state.step4currentemploymentreasonforleaving = step4currentemploymentreasonforleaving
  },
  'STEP4CURRENTEMPLOYMENTREASONFORLEAVING2' (state, step4currentemploymentreasonforleaving2) {
    state.step4currentemploymentreasonforleaving2 = step4currentemploymentreasonforleaving2
  },
  'STEP4ADDITIONALINCOMEINCOMESOURCE' (state, step4additionalincomeincomesource) {
    state.step4additionalincomeincomesource = step4additionalincomeincomesource
  },
  'STEP4ADDITIONALINCOMEINCOMESOURCE2' (state, step4additionalincomeincomesource2) {
    state.step4additionalincomeincomesource2 = step4additionalincomeincomesource2
  },
  'STEP4ADDITIONALINCOMEAMOUNT' (state, step4additionalincomeamount) {
    state.step4additionalincomeamount = step4additionalincomeamount
  },
  'STEP4ADDITIONALINCOMEAMOUNT2' (state, step4additionalincomeamount2) {
    state.step4additionalincomeamount2 = step4additionalincomeamount2
  },
  'STEP4ADDITIONALINCOMETELEPHONE' (state, step4additionalincometelephone) {
    state.step4additionalincometelephone = step4additionalincometelephone
  },
  'STEP4ADDITIONALINCOMETELEPHONE2' (state, step4additionalincometelephone2) {
    state.step4additionalincometelephone2 = step4additionalincometelephone2
  },
  'STEP4ADDITIONALINCOMEVERIFYWITH' (state, step4additionalincomeverifywith) {
    state.step4additionalincomeverifywith = step4additionalincomeverifywith
  },
  'STEP4ADDITIONALINCOMEVERIFYWITH2' (state, step4additionalincomeverifywith2) {
    state.step4additionalincomeverifywith2 = step4additionalincomeverifywith2
  },
  'STEP5NAME1' (state, step5name1) {
    state.step5name1 = step5name1
  },
  'STEP5NAME12' (state, step5name12) {
    state.step5name12 = step5name12
  },
  'STEP5TELEPHONE1' (state, step5telephone1) {
    state.step5telephone1 = step5telephone1
  },
  'STEP5TELEPHONE12' (state, step5telephone12) {
    state.step5telephone12 = step5telephone12
  },
  'STEP5RELATIONSHIP1' (state, step5relationship1) {
    state.step5relationship1 = step5relationship1
  },
  'STEP5RELATIONSHIP12' (state, step5relationship12) {
    state.step5relationship12 = step5relationship12
  },
  'STEP6PET1TYPE' (state, step6pet1type) {
    state.step6pet1type = step6pet1type
  },
  'STEP6PET2TYPE' (state, step6pet2type) {
    state.step6pet2type = step6pet2type
  },
  'STEP6PET3TYPE' (state, step6pet3type) {
    state.step6pet3type = step6pet3type
  },
  'STEP6PET1BREED' (state, step6pet1breed) {
    state.step6pet1breed = step6pet1breed
  },
  'STEP6PET2BREED' (state, step6pet2breed) {
    state.step6pet2breed = step6pet2breed
  },
  'STEP6PET3BREED' (state, step6pet3breed) {
    state.step6pet3breed = step6pet3breed
  },
  'STEP6PET1WEIGHT' (state, step6pet1weight) {
    state.step6pet1weight = step6pet1weight
  },
  'STEP6PET2WEIGHT' (state, step6pet2weight) {
    state.step6pet2weight = step6pet2weight
  },
  'STEP6PET3WEIGHT' (state, step6pet3weight) {
    state.step6pet3weight = step6pet3weight
  },
  'STEP6CRIMINAL1MISDEMEANORS' (state, step6criminal1misdemeanors) {
    state.step6criminal1misdemeanors = step6criminal1misdemeanors
  },
  'STEP6CRIMINAL2EVICTIONS' (state, step6criminal2evictions) {
    state.step6criminal2evictions = step6criminal2evictions
  },
  'STEP6AGREEMENTS1' (state, step6agreements1) {
    state.step6agreements1 = step6agreements1
  },
  'STEP6AGREEMENTS2' (state, step6agreements2) {
    state.step6agreements2 = step6agreements2
  },
  'STEP6AGREEMENTS3' (state, step6agreements3) {
    state.step6agreements3 = step6agreements3
  },
  'STEP6AGREEMENTS4' (state, step6agreements4) {
    state.step6agreements4 = step6agreements4
  },
  'STEP7FIRSTNAME' (state, step7firstname) {
    state.step7firstname = step7firstname
  },
  'STEP7LASTNAME' (state, step7lastname) {
    state.step7lastname = step7lastname
  },
  'STEP7NAMESHOWNONCARD' (state, step7nameshownoncard) {
    state.step7nameshownoncard = step7nameshownoncard
  },
  'STEP7EMAILADDRESS' (state, step7emailaddress) {
    state.step7emailaddress = step7emailaddress
  },
  'STEP7ADDRESS' (state, step7address) {
    state.step7address = step7address
  },
  'STEP7CITY' (state, step7city) {
    state.step7city = step7city
  },
  'STEP7STATE' (state, step7state) {
    state.step7state = step7state
  },
  'STEP7ZIP' (state, step7zip) {
    state.step7zip = step7zip
  },
  'STEP7CREDITCARDTYPE' (state, step7creditcardtype) {
    state.step7creditcardtype = step7creditcardtype
  },
  'STEP7CREDITCARDNUMBER' (state, step7creditcardnumber) {
    state.step7creditcardnumber = step7creditcardnumber
  },
  'STEP7EXPIRATIONDATECREDITCARD' (state, step7expirationdatecreditcard) {
    state.step7expirationdatecreditcard = step7expirationdatecreditcard
  },
  'STEP7EXPIRATIONDATECREDITCARDYEAR' (state, step7expirationdatecreditcardyear) {
    state.step7expirationdatecreditcardyear = step7expirationdatecreditcardyear
  },
  'STEP7CVI' (state, step7cvi) {
    state.step7cvi = step7cvi
  },
  'CLEAR_ALL_GLOBAL' (state) {
    for (var key in state) {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        state[key] = ''
      }
    }  
  }
}

const actions = {
  clearAllGlobal ({commit}) {
    commit('CLEAR_ALL_GLOBAL')
  },
  setLanguage ({commit}, language) {
    commit('LANGUAGE', language)
  },
  setMoveInDate ({commit}, moveindate) {
    commit('MOVEINDATE', moveindate)
  },
  setComments ({commit}, comments) {
    commit('COMMENTS', comments)
  },
  setDesiredapartment ({commit}, desiredapartment) {
    commit('DESIREDAPARTMENT', desiredapartment)
  },
  setNumberoccupants ({commit}, numberoccupants) {
    commit('NUMBEROCCUPANTS', numberoccupants)
  },
  setComplexid ({commit}, complexid) {
    commit('COMPLEXID', complexid)
  },
  setDorental ({commit}, dorental) {
    commit('DORENTAL', dorental)
  },
  setDoemployment ({commit}, doemployment) {
    commit('DOEMPLOYMENT', doemployment)
  },
  setDocoapp ({commit}, docoapp) {
    commit('COAPP', docoapp)
  },
  setDoevictions ({commit}, doevictions) {
    commit('DOEVICTIONS', doevictions)
  },
  setEvictionstate ({commit}, evictionstate) {
    commit('EVICTIONSTATE', evictionstate)
  },
  setCoevictionstate ({commit}, coevictionstate) {
    commit('COEVICTIONSTATE', coevictionstate)
  },
  setComplexname ({commit}, complexname) {
    commit('COMPLEXNAME', complexname)
  },
  setComplexapplicationfee ({commit}, complexapplicationfee) {
    commit('COMPLEXAPPLICATIONFEE', complexapplicationfee)
  },
  setRaapplicationfee ({commit}, raapplicationfee) {
    commit('RAAPPLICATIONFEE', raapplicationfee)
  },
  setAdultapplicationfee ({commit}, adultapplicationfee) {
    commit('ADULTAPPLICATIONFEE', adultapplicationfee)
  },
  setEmploymentreportprice ({commit}, employmentreportprice) {
    commit('EMPLOYMENTREPORTPRICE', employmentreportprice)
  },
  setRentalreportprice ({commit}, rentalreportprice) {
    commit('RENTALREPORTPRICE', rentalreportprice)
  },
  setEvictionprice ({commit}, evictionprice) {
    commit('EVICTIONPRICE', evictionprice)
  },
  setBackgroundcreditreportcost ({commit}, backgroundcreditreportcost) {
    commit('BACKGROUNDCREDITREPORTCOST', backgroundcreditreportcost)
  },
  setOccupants ({commit}, occupants) {
    commit('SET_OCCUPANTS', occupants)
  },
  updateOccupant ({commit}, occupant) {
    commit('UPDATE_OCCUPANT', occupant)
  },
  updateStep2applicantfirstname ({commit}, step2applicantfirstname) {
    commit('STEP2APPLICANTFIRSTNAME', step2applicantfirstname)
  },
  updateStep2applicantlastname ({commit}, step2applicantlastname) {
    commit('STEP2APPLICANTLASTNAME', step2applicantlastname)
  },
  updateStep2applicantssn1 ({commit}, step2applicantssn1) {
    commit('STEP2APPLICANTSSN1', step2applicantssn1)
  },
  updateStep2applicantssn2 ({commit}, step2applicantssn2) {
    commit('STEP2APPLICANTSSN2', step2applicantssn2)
  },
  updateStep2applicantdob ({commit}, step2applicantdob) {
    commit('STEP2APPLICANTDOB', step2applicantdob)
  },
  updateStep2applicantphone ({commit}, step2applicantphone) {
    commit('STEP2APPLICANTPHONE', step2applicantphone)
  },
  updateStep2applicantdriverslicense ({commit}, step2applicantdriverslicense) {
    commit('STEP2APPLICANTDRIVERSLICENSE', step2applicantdriverslicense)
  },
  updateStep2applicantdriverslicensestate ({commit}, step2applicantdriverslicensestate) {
    commit('STEP2APPLICANTDRIVERSLICENSESTATE', step2applicantdriverslicensestate)
  },
  updateStep2applicantsmoking ({commit}, step2applicantsmoking) {
    commit('STEP2APPLICANTSMOKING', step2applicantsmoking)
  },
  updateStep2applicantemail ({commit}, step2applicantemail) {
    commit('STEP2APPLICANTEMAIL', step2applicantemail)
  },
  updateStep2coapplicantfirstname ({commit}, step2coapplicantfirstname) {
    commit('STEP2COAPPLICANTFIRSTNAME', step2coapplicantfirstname)
  },
  updateStep2coapplicantlastname ({commit}, step2coapplicantlastname) {
    commit('STEP2COAPPLICANTLASTNAME', step2coapplicantlastname)
  },
  updateStep2coapplicantssn1 ({commit}, step2coapplicantssn1) {
    commit('STEP2COAPPLICANTSSN1', step2coapplicantssn1)
  },
  updateStep2coapplicantssn2 ({commit}, step2coapplicantssn2) {
    commit('STEP2COAPPLICANTSSN2', step2coapplicantssn2)
  },
  updateStep2coapplicantdob ({commit}, step2coapplicantdob) {
    commit('STEP2COAPPLICANTDOB', step2coapplicantdob)
  },
  updateStep2coapplicantphone ({commit}, step2coapplicantphone) {
    commit('STEP2COAPPLICANTPHONE', step2coapplicantphone)
  },
  updateStep2coapplicantdriverslicense ({commit}, step2coapplicantdriverslicense) {
    commit('STEP2COAPPLICANTDRIVERSLICENSE', step2coapplicantdriverslicense)
  },
  updateStep2coapplicantdriverslicensestate ({commit}, step2coapplicantdriverslicensestate) {
    commit('STEP2COAPPLICANTDRIVERSLICENSESTATE', step2coapplicantdriverslicensestate)
  },
  updateStep2coapplicantsmoking ({commit}, step2coapplicantsmoking) {
    commit('STEP2COAPPLICANTSMOKING', step2coapplicantsmoking)
  },
  updateStep2coapplicantemail ({commit}, step2coapplicantemail) {
    commit('STEP2COAPPLICANTEMAIL', step2coapplicantemail)
  },
  updateStep3currentaddresscurrentapartmentname ({commit}, step3currentaddresscurrentapartmentname) {
    commit('STEP3CURRENTADDRESSCURRENTAPARTMENTNAME', step3currentaddresscurrentapartmentname)
  },
  updateStep3currentaddressmanagersname ({commit}, step3currentaddressmanagersname) {
    commit('STEP3CURRENTADDRESSMANAGERSNAME', step3currentaddressmanagersname)
  },
  updateStep3currentaddresstelephone ({commit}, step3currentaddresstelephone) {
    commit('STEP3CURRENTADDRESSTELEPHONE', step3currentaddresstelephone)
  },
  updateStep3currentaddressaddress1 ({commit}, step3currentaddressaddress1) {
    commit('STEP3CURRENTADDRESSADDRESS1', step3currentaddressaddress1)
  },
  updateStep3currentaddresscity ({commit}, step3currentaddresscity) {
    commit('STEP3CURRENTADDRESSCITY', step3currentaddresscity)
  },
  updateStep3currentaddressstate ({commit}, step3currentaddressstate) {
    commit('STEP3CURRENTADDRESSSTATE', step3currentaddressstate)
  },
  updateStep3currentaddresszip ({commit}, step3currentaddresszip) {
    commit('STEP3CURRENTADDRESSZIP', step3currentaddresszip)
  },
  updateStep3currentaddressmoveindate ({commit}, step3currentaddressmoveindate) {
    commit('STEP3CURRENTADDRESSMOVEINDATE', step3currentaddressmoveindate)
  },
  updateStep3currentaddressmoveindateyear ({commit}, step3currentaddressmoveindateyear) {
    commit('STEP3CURRENTADDRESSMOVEINDATEYEAR', step3currentaddressmoveindateyear)
  },
  updateStep3currentaddressreasonforleaving ({commit}, step3currentaddressreasonforleaving) {
    commit('STEP3CURRENTADDRESSREASONFORLEAVING', step3currentaddressreasonforleaving)
  },
  updateStep3currentaddressrentamount ({commit}, step3currentaddressrentamount) {
    commit('STEP3CURRENTADDRESSRENTAMOUNT', step3currentaddressrentamount)
  },
  updateStep3CurrentAddressCurrentApartmentCheckedSameAsApplicant ({commit}, step3CurrentAddressCurrentApartmentCheckedSameAsApplicant) {
    commit('STEP3CURRENTADDRESSCURRENTAPARTMENTCHECKEDSAMEASAPPLICANT', step3CurrentAddressCurrentApartmentCheckedSameAsApplicant)
  },
  updateStep3CheckedNoPreviousApartment ({commit}, step3checkednopreviousapartment) {
    commit('STEP3CHECKEDNOPREVIOUSAPARTMENT', step3checkednopreviousapartment)
  },
  updateStep3CheckedNoPreviousApartmentCoApplicant ({commit}, step3checkednopreviousapartmentcoapplicant) {
    commit('STEP3CHECKEDNOPREVIOUSAPARTMENTCOAPPLICANT', step3checkednopreviousapartmentcoapplicant)
  },
  updateStep3currentaddresscurrentapartmentname2 ({commit}, step3currentaddresscurrentapartmentname2) {
    commit('STEP3CURRENTADDRESSCURRENTAPARTMENTNAME2', step3currentaddresscurrentapartmentname2)
  },
  updateStep3currentaddressmanagersname2 ({commit}, step3currentaddressmanagersname2) {
    commit('STEP3CURRENTADDRESSMANAGERSNAME2', step3currentaddressmanagersname2)
  },
  updateStep3currentaddresstelephone2 ({commit}, step3currentaddresstelephone2) {
    commit('STEP3CURRENTADDRESSTELEPHONE2', step3currentaddresstelephone2)
  },
  updateStep3currentaddressaddress12 ({commit}, step3currentaddressaddress12) {
    commit('STEP3CURRENTADDRESSADDRESS12', step3currentaddressaddress12)
  },
  updateStep3currentaddresscity2 ({commit}, step3currentaddresscity2) {
    commit('STEP3CURRENTADDRESSCITY2', step3currentaddresscity2)
  },
  updateStep3currentaddressstate2 ({commit}, step3currentaddressstate2) {
    commit('STEP3CURRENTADDRESSSTATE2', step3currentaddressstate2)
  },
  updateStep3currentaddresszip2 ({commit}, step3currentaddresszip2) {
    commit('STEP3CURRENTADDRESSZIP2', step3currentaddresszip2)
  },
  updateStep3currentaddressmoveindate2 ({commit}, step3currentaddressmoveindate2) {
    commit('STEP3CURRENTADDRESSMOVEINDATE2', step3currentaddressmoveindate2)
  },
  updateStep3currentaddressmoveindate2year ({commit}, step3currentaddressmoveindate2year) {
    commit('STEP3CURRENTADDRESSMOVEINDATE2YEAR', step3currentaddressmoveindate2year)
  },
  updateStep3currentaddressreasonforleaving2 ({commit}, step3currentaddressreasonforleaving2) {
    commit('STEP3CURRENTADDRESSREASONFORLEAVING2', step3currentaddressreasonforleaving2)
  },
  updateStep3currentaddressrentamount2 ({commit}, step3currentaddressrentamount2) {
    commit('STEP3CURRENTADDRESSRENTAMOUNT2', step3currentaddressrentamount2)
  },
  updateStep3previousaddressmanagersname ({commit}, step3previousaddressmanagersname) {
    commit('STEP3PREVIOUSADDRESSMANAGERSNAME', step3previousaddressmanagersname)
  },
  updateStep3previousaddressaddress1 ({commit}, step3previousaddressaddress1) {
    commit('STEP3PREVIOUSADDRESSADDRESS1', step3previousaddressaddress1)
  },
  updateStep3previousaddresscity ({commit}, step3previousaddresscity) {
    commit('STEP3PREVIOUSADDRESSCITY', step3previousaddresscity)
  },
  updateStep3previousaddressstate ({commit}, step3previousaddressstate) {
    commit('STEP3PREVIOUSADDRESSSTATE', step3previousaddressstate)
  },
  updateStep3previousaddresszip ({commit}, step3previousaddresszip) {
    commit('STEP3PREVIOUSADDRESSZIP', step3previousaddresszip)
  },
  updateStep3previousaddressrentamount ({commit}, step3previousaddressrentamount) {
    commit('STEP3PREVIOUSADDRESSRENTAMOUNT', step3previousaddressrentamount)
  },
  updateStep3previousaddressmoveindate ({commit}, step3previousaddressmoveindate) {
    commit('STEP3PREVIOUSADDRESSMOVEINDATE', step3previousaddressmoveindate)
  },
  updateStep3previousaddressmoveindateyear ({commit}, step3previousaddressmoveindateyear) {
    commit('STEP3PREVIOUSADDRESSMOVEINDATEYEAR', step3previousaddressmoveindateyear)
  },
  updateStep3previousaddressreasonforleaving ({commit}, step3previousaddressreasonforleaving) {
    commit('STEP3PREVIOUSADDRESSREASONFORLEAVING', step3previousaddressreasonforleaving)
  },
  updateStep3previousaddressapartmentname ({commit}, step3previousaddressapartmentname) {
    commit('STEP3PREVIOUSADDRESSAPARTMENTNAME', step3previousaddressapartmentname)
  },
  updateStep3previousaddressapartmentname2 ({commit}, step3previousaddressapartmentname2) {
    commit('STEP3PREVIOUSADDRESSAPARTMENTNAME2', step3previousaddressapartmentname2)
  },
  updateStep3previousaddressmanagersname2 ({commit}, step3previousaddressmanagersname2) {
    commit('STEP3PREVIOUSADDRESSMANAGERSNAME2', step3previousaddressmanagersname2)
  },
  updateStep3previousaddresstelephone2 ({commit}, step3previousaddresstelephone2) {
    commit('STEP3PREVIOUSADDRESSTELEPHONE2', step3previousaddresstelephone2)
  },
  updateStep3previousaddresstelephone ({commit}, step3previousaddresstelephone) {
    commit('STEP3PREVIOUSADDRESSTELEPHONE', step3previousaddresstelephone)
  },
  updateStep3previousaddressaddress12 ({commit}, step3previousaddressaddress12) {
    commit('STEP3PREVIOUSADDRESSADDRESS12', step3previousaddressaddress12)
  },
  updateStep3previousaddresscity2 ({commit}, step3previousaddresscity2) {
    commit('STEP3PREVIOUSADDRESSCITY2', step3previousaddresscity2)
  },
  updateStep3previousaddressstate2 ({commit}, step3previousaddressstate2) {
    commit('STEP3PREVIOUSADDRESSSTATE2', step3previousaddressstate2)
  },
  updateStep3previousaddresszip2 ({commit}, step3previousaddresszip2) {
    commit('STEP3PREVIOUSADDRESSZIP2', step3previousaddresszip2)
  },
  updateStep3previousaddressrentamount2 ({commit}, step3previousaddressrentamount2) {
    commit('STEP3PREVIOUSADDRESSRENTAMOUNT2', step3previousaddressrentamount2)
  },
  updateStep3previousaddressmoveindate2 ({commit}, step3previousaddressmoveindate2) {
    commit('STEP3PREVIOUSADDRESSMOVEINDATE2', step3previousaddressmoveindate2)
  },
  updateStep3previousaddressmoveindate2year ({commit}, step3previousaddressmoveindate2year) {
    commit('STEP3PREVIOUSADDRESSMOVEINDATE2YEAR', step3previousaddressmoveindate2year)
  },
  updateStep3previousaddressmoveoutdate2 ({commit}, step3previousaddressmoveoutdate2) {
    commit('STEP3PREVIOUSADDRESSMOVEOUTDATE2', step3previousaddressmoveoutdate2)
  },
  updateStep3previousaddressmoveoutdate2year ({commit}, step3previousaddressmoveoutdate2year) {
    commit('STEP3PREVIOUSADDRESSMOVEOUTDATE2YEAR', step3previousaddressmoveoutdate2year)
  },
  updateStep3previousaddressmoveoutdate ({commit}, step3previousaddressmoveoutdate) {
    commit('STEP3PREVIOUSADDRESSMOVEOUTDATE', step3previousaddressmoveoutdate)
  },
  updateStep3previousaddressmoveoutdateyear ({commit}, step3previousaddressmoveoutdateyear) {
    commit('STEP3PREVIOUSADDRESSMOVEOUTDATEYEAR', step3previousaddressmoveoutdateyear)
  },
  updateStep3previousaddressreasonforleaving2 ({commit}, step3previousaddressreasonforleaving2) {
    commit('STEP3PREVIOUSADDRESSREASONFORLEAVING2', step3previousaddressreasonforleaving2)
  },
  updateStep4NotCurrentlyEmployed ({commit}, step4notcurrentlyemployed) {
    commit('STEP4NOTCURRENTLYEMPLOYED', step4notcurrentlyemployed)
  },
  updateStep4NotCurrentlyEmployedCoApplicant ({commit}, step4notcurrentlyemployedcoapplicant) {
    commit('STEP4NOTCURRENTLYEMPLOYEDCOAPPLICANT', step4notcurrentlyemployedcoapplicant)
  },
  updateStep4currentemploymentcompanyname ({commit}, step4currentemploymentcompanyname) {
    commit('STEP4CURRENTEMPLOYMENTCOMPANYNAME', step4currentemploymentcompanyname)
  },
  updateStep4currentemploymentcompanyname2 ({commit}, step4currentemploymentcompanyname2) {
    commit('STEP4CURRENTEMPLOYMENTCOMPANYNAME2', step4currentemploymentcompanyname2)
  },
  updateStep4currentemploymentsupervisorsname ({commit}, step4currentemploymentsupervisorsname) {
    commit('STEP4CURRENTEMPLOYMENTSUPERVISORSNAME', step4currentemploymentsupervisorsname)
  },
  updateStep4currentemploymentsupervisorsname2 ({commit}, step4currentemploymentsupervisorsname2) {
    commit('STEP4CURRENTEMPLOYMENTSUPERVISORSNAME2', step4currentemploymentsupervisorsname2)
  },
  updateStep4currentemploymenttelephone ({commit}, step4currentemploymenttelephone) {
    commit('STEP4CURRENTEMPLOYMENTTELEPHONE', step4currentemploymenttelephone)
  },
  updateStep4currentemploymenttelephone2 ({commit}, step4currentemploymenttelephone2) {
    commit('STEP4CURRENTEMPLOYMENTTELEPHONE2', step4currentemploymenttelephone2)
  },
  updateStep4currentemploymentaddress1 ({commit}, step4currentemploymentaddress1) {
    commit('STEP4CURRENTEMPLOYMENTADDRESS1', step4currentemploymentaddress1)
  },
  updateStep4currentemploymentaddress12 ({commit}, step4currentemploymentaddress12) {
    commit('STEP4CURRENTEMPLOYMENTADDRESS12', step4currentemploymentaddress12)
  },
  updateStep4currentemploymentcity ({commit}, step4currentemploymentcity) {
    commit('STEP4CURRENTEMPLOYMENTCITY', step4currentemploymentcity)
  },
  updateStep4currentemploymentcity2 ({commit}, step4currentemploymentcity2) {
    commit('STEP4CURRENTEMPLOYMENTCITY2', step4currentemploymentcity2)
  },
  updateStep4currentemploymentstate ({commit}, step4currentemploymentstate) {
    commit('STEP4CURRENTEMPLOYMENTSTATE', step4currentemploymentstate)
  },
  updateStep4currentemploymentstate2 ({commit}, step4currentemploymentstate2) {
    commit('STEP4CURRENTEMPLOYMENTSTATE2', step4currentemploymentstate2)
  },
  updateStep4currentemploymentzip ({commit}, step4currentemploymentzip) {
    commit('STEP4CURRENTEMPLOYMENTZIP', step4currentemploymentzip)
  },
  updateStep4currentemploymentzip2 ({commit}, step4currentemploymentzip2) {
    commit('STEP4CURRENTEMPLOYMENTZIP2', step4currentemploymentzip2)
  },
  updateStep4currentemploymentsalary ({commit}, step4currentemploymentsalary) {
    commit('STEP4CURRENTEMPLOYMENTSALARY', step4currentemploymentsalary)
  },
  updateStep4currentemploymentsalary2 ({commit}, step4currentemploymentsalary2) {
    commit('STEP4CURRENTEMPLOYMENTSALARY2', step4currentemploymentsalary2)
  },
  updateStep4currentemploymenthoursperweek ({commit}, step4currentemploymenthoursperweek) {
    commit('STEP4CURRENTEMPLOYMENTHOURSPERWEEK', step4currentemploymenthoursperweek)
  },
  updateStep4currentemploymenthoursperweek2 ({commit}, step4currentemploymenthoursperweek2) {
    commit('STEP4CURRENTEMPLOYMENTHOURSPERWEEK2', step4currentemploymenthoursperweek2)
  },
  updateStep4currentemploymentstartdate ({commit}, step4currentemploymentstartdate) {
    commit('STEP4CURRENTEMPLOYMENTSTARTDATE', step4currentemploymentstartdate)
  },
  updateStep4currentemploymentstartdateyear ({commit}, step4currentemploymentstartdateyear) {
    commit('STEP4CURRENTEMPLOYMENTSTARTDATEYEAR', step4currentemploymentstartdateyear)
  },
  updateStep4currentemploymentstartdate2 ({commit}, step4currentemploymentstartdate2) {
    commit('STEP4CURRENTEMPLOYMENTSTARTDATE2', step4currentemploymentstartdate2)
  },
  updateStep4currentemploymentstartdate2year ({commit}, step4currentemploymentstartdate2year) {
    commit('STEP4CURRENTEMPLOYMENTSTARTDATE2YEAR', step4currentemploymentstartdate2year)
  },
  updateStep4currentemploymentenddate ({commit}, step4currentemploymentenddate) {
    commit('STEP4CURRENTEMPLOYMENTENDDATE', step4currentemploymentenddate)
  },
  updateStep4currentemploymentenddateyear ({commit}, step4currentemploymentenddateyear) {
    commit('STEP4CURRENTEMPLOYMENTENDDATEYEAR', step4currentemploymentenddateyear)
  },
  updateStep4currentemploymentenddate2 ({commit}, step4currentemploymentenddate2) {
    commit('STEP4CURRENTEMPLOYMENTENDDATE2', step4currentemploymentenddate2)
  },
  updateStep4currentemploymentenddate2year ({commit}, step4currentemploymentenddate2year) {
    commit('STEP4CURRENTEMPLOYMENTENDDATE2YEAR', step4currentemploymentenddate2year)
  },
  updateStep4currentemploymentreasonforleaving ({commit}, step4currentemploymentreasonforleaving) {
    commit('STEP4CURRENTEMPLOYMENTREASONFORLEAVING', step4currentemploymentreasonforleaving)
  },
  updateStep4currentemploymentreasonforleaving2 ({commit}, step4currentemploymentreasonforleaving2) {
    commit('STEP4CURRENTEMPLOYMENTREASONFORLEAVING2', step4currentemploymentreasonforleaving2)
  },
  updateStep4additionalincomeincomesource ({commit}, step4additionalincomeincomesource) {
    commit('STEP4ADDITIONALINCOMEINCOMESOURCE', step4additionalincomeincomesource)
  },
  updateStep4additionalincomeincomesource2 ({commit}, step4additionalincomeincomesource2) {
    commit('STEP4ADDITIONALINCOMEINCOMESOURCE2', step4additionalincomeincomesource2)
  },
  updateStep4additionalincomeamount ({commit}, step4additionalincomeamount) {
    commit('STEP4ADDITIONALINCOMEAMOUNT', step4additionalincomeamount)
  },
  updateStep4additionalincomeamount2 ({commit}, step4additionalincomeamount2) {
    commit('STEP4ADDITIONALINCOMEAMOUNT2', step4additionalincomeamount2)
  },
  updateStep4additionalincometelephone ({commit}, step4additionalincometelephone) {
    commit('STEP4ADDITIONALINCOMETELEPHONE', step4additionalincometelephone)
  },
  updateStep4additionalincometelephone2 ({commit}, step4additionalincometelephone2) {
    commit('STEP4ADDITIONALINCOMETELEPHONE2', step4additionalincometelephone2)
  },
  updateStep4additionalincomeverifywith ({commit}, step4additionalincomeverifywith) {
    commit('STEP4ADDITIONALINCOMEVERIFYWITH', step4additionalincomeverifywith)
  },
  updateStep4additionalincomeverifywith2 ({commit}, step4additionalincomeverifywith2) {
    commit('STEP4ADDITIONALINCOMEVERIFYWITH2', step4additionalincomeverifywith2)
  },
  updateStep5name1 ({commit}, step5name1) {
    commit('STEP5NAME1', step5name1)
  },
  updateStep5name12 ({commit}, step5name12) {
    commit('STEP5NAME12', step5name12)
  },
  updateStep5telephone1 ({commit}, step5telephone1) {
    commit('STEP5TELEPHONE1', step5telephone1)
  },
  updateStep5telephone12 ({commit}, step5telephone12) {
    commit('STEP5TELEPHONE12', step5telephone12)
  },
  updateStep5relationship1 ({commit}, step5relationship1) {
    commit('STEP5RELATIONSHIP1', step5relationship1)
  },
  updateStep5relationship12 ({commit}, step5relationship12) {
    commit('STEP5RELATIONSHIP12', step5relationship12)
  },
  updateStep6pet1type ({commit}, step6pet1type) {
    commit('STEP6PET1TYPE', step6pet1type)
  },
  updateStep6pet2type ({commit}, step6pet2type) {
    commit('STEP6PET2TYPE', step6pet2type)
  },
  updateStep6pet3type ({commit}, step6pet3type) {
    commit('STEP6PET3TYPE', step6pet3type)
  },
  updateStep6pet1breed ({commit}, step6pet1breed) {
    commit('STEP6PET1BREED', step6pet1breed)
  },
  updateStep6pet2breed ({commit}, step6pet2breed) {
    commit('STEP6PET2BREED', step6pet2breed)
  },
  updateStep6pet3breed ({commit}, step6pet3breed) {
    commit('STEP6PET3BREED', step6pet3breed)
  },
  updateStep6pet1weight ({commit}, step6pet1weight) {
    commit('STEP6PET1WEIGHT', step6pet1weight)
  },
  updateStep6pet2weight ({commit}, step6pet2weight) {
    commit('STEP6PET2WEIGHT', step6pet2weight)
  },
  updateStep6pet3weight ({commit}, step6pet3weight) {
    commit('STEP6PET3WEIGHT', step6pet3weight)
  },
  updateStep6criminal1misdemeanors ({commit}, step6criminal1misdemeanors) {
    commit('STEP6CRIMINAL1MISDEMEANORS', step6criminal1misdemeanors)
  },
  updateStep6criminal2evictions ({commit}, step6criminal2evictions) {
    commit('STEP6CRIMINAL2EVICTIONS', step6criminal2evictions)
  },
  updateStep6agreements1 ({commit}, step6agreements1) {
    commit('STEP6AGREEMENTS1', step6agreements1)
  },
  updateStep6agreements2 ({commit}, step6agreements2) {
    commit('STEP6AGREEMENTS2', step6agreements2)
  },
  updateStep6agreements3 ({commit}, step6agreements3) {
    commit('STEP6AGREEMENTS3', step6agreements3)
  },
  updateStep6agreements4 ({commit}, step6agreements4) {
    commit('STEP6AGREEMENTS4', step6agreements4)
  },
  updateStep7firstname ({commit}, step7firstname) {
    commit('STEP7FIRSTNAME', step7firstname)
  },
  updateStep7lastname ({commit}, step7lastname) {
    commit('STEP7LASTNAME', step7lastname)
  },
  updateStep7nameshownoncard ({commit}, step7nameshownoncard) {
    commit('STEP7NAMESHOWNONCARD', step7nameshownoncard)
  },
  updateStep7emailaddress ({commit}, step7emailaddress) {
    commit('STEP7EMAILADDRESS', step7emailaddress)
  },
  updateStep7address ({commit}, step7address) {
    commit('STEP7ADDRESS', step7address)
  },
  updateStep7city ({commit}, step7city) {
    commit('STEP7CITY', step7city)
  },
  updateStep7state ({commit}, step7state) {
    commit('STEP7STATE', step7state)
  },
  updateStep7zip ({commit}, step7zip) {
    commit('STEP7ZIP', step7zip)
  },
  updateStep7creditcardtype ({commit}, step7creditcardtype) {
    commit('STEP7CREDITCARDTYPE', step7creditcardtype)
  },
  updateStep7creditcardnumber ({commit}, step7creditcardnumber) {
    commit('STEP7CREDITCARDNUMBER', step7creditcardnumber)
  },
  updateStep7expirationdatecreditcard ({commit}, step7expirationdatecreditcard) {
    commit('STEP7EXPIRATIONDATECREDITCARD', step7expirationdatecreditcard)
  },
  updateStep7expirationdatecreditcardyear ({commit}, step7expirationdatecreditcardyear) {
    commit('STEP7EXPIRATIONDATECREDITCARDYEAR', step7expirationdatecreditcardyear)
  },
  updateStep7cvi ({commit}, step7cvi) {
    commit('STEP7CVI', step7cvi)
  }
}

const getters = {
  getTotalCharge (state) {
    let totalfee = 0.00
    let rentalfee = 0.00
    let evictionfee = 0.00
    let employmentfee = 0.00
    let applicationfee = 0.00
    // rental
    if (state.dorental === 'true') {
      if (state.docoapp === 'true') {
        rentalfee = state.rentalreportprice * 2
      } else {
        rentalfee = state.rentalreportprice
      }
    } else {
      rentalfee = 0.00
    }
    // evictions
    if (state.doevictions === 'true') {
      if (state.docoapp === 'true') {
        evictionfee = state.evictionprice * 2
      } else {
        evictionfee = state.evictionprice
      }
    } else {
      evictionfee = 0.00
    }
    // employment
    if (state.doemployment === 'true') {
      if (state.docoapp === 'true') {
        employmentfee = state.employmentreportprice * 2
      } else {
        employmentfee = state.employmentreportprice
      }
    } else {
      employmentfee = 0.00
    }
    // application fee
    applicationfee = Number(state.adultapplicationfee)
    if (state.docoapp === 'true') {
      applicationfee = applicationfee * 2
    }
    // calculate total fee
    try{
      totalfee = parseFloat(rentalfee) + parseFloat(evictionfee) + parseFloat(employmentfee) + parseFloat(applicationfee)
    }catch(e){
      console.error(e);
    }
    return totalfee
  },
  language (state) {
    return state.language
  },
  moveindate (state) {
    return state.moveindate
  },
  comments (state) {
    return state.comments
  },
  desiredapartment (state) {
    return state.desiredapartment
  },
  numberoccupants (state) {
    return state.numberoccupants
  },
  complexid (state) {
    return state.complexid
  },
  dorental (state) {
    return state.dorental
  },
  doemployment (state) {
    return state.doemployment
  },
  docoapp (state) {
    return state.docoapp
  },
  doevictions (state) {
    return state.doevictions
  },
  evictionstate (state) {
    return state.evictionstate
  },
  coevictionstate (state) {
    return state.coevictionstate
  },
  complexname (state) {
    return state.complexname
  },
  complexapplicationfee (state) {
    return state.complexapplicationfee
  },
  raapplicationfee (state) {
    return state.raapplicationfee
  },
  adultapplicationfee (state) {
    return state.adultapplicationfee
  },
  employmentreportprice (state) {
    return state.employmentreportprice
  },
  rentalreportprice (state) {
    return state.rentalreportprice
  },
  evictionprice (state) {
    return state.evictionprice
  },
  backgroundcreditreportcost (state) {
    return state.backgroundcreditreportcost
  },
  occupants (state) {
    return state.occupants
  },
  occupant (state, id) {
    for (var i = 0; i < state.occupants.length; i++) {
      if (state.occupants[i].id === id) {
        return state.occupants[i]
      }
    }
    return {}
  },
  step2applicantfirstname (state) {
    return state.step2applicantfirstname
  },
  step2applicantlastname (state) {
    return state.step2applicantlastname
  },
  step2applicantssn1 (state) {
    return state.step2applicantssn1
  },
  step2applicantssn2 (state) {
    return state.step2applicantssn2
  },
  step2applicantdob (state) {
    return state.step2applicantdob
  },
  step2applicantphone (state) {
    return state.step2applicantphone
  },
  step2applicantdriverslicense (state) {
    return state.step2applicantdriverslicense
  },
  step2applicantdriverslicensestate (state) {
    return state.step2applicantdriverslicensestate
  },
  step2applicantsmoking (state) {
    return state.step2applicantsmoking
  },
  step2applicantemail (state) {
    return state.step2applicantemail
  },
  step2coapplicantfirstname (state) {
    return state.step2coapplicantfirstname
  },
  step2coapplicantlastname (state) {
    return state.step2coapplicantlastname
  },
  step2coapplicantssn1 (state) {
    return state.step2coapplicantssn1
  },
  step2coapplicantssn2 (state) {
    return state.step2coapplicantssn2
  },
  step2coapplicantdob (state) {
    return state.step2coapplicantdob
  },
  step2coapplicantphone (state) {
    return state.step2coapplicantphone
  },
  step2coapplicantdriverslicense (state) {
    return state.step2coapplicantdriverslicense
  },
  step2coapplicantdriverslicensestate (state) {
    return state.step2coapplicantdriverslicensestate
  },
  step2coapplicantsmoking (state) {
    return state.step2coapplicantsmoking
  },
  step2coapplicantemail (state) {
    return state.step2coapplicantemail
  },
  step3currentaddresscurrentapartmentname (state) {
    return state.step3currentaddresscurrentapartmentname
  },
  step3currentaddressmanagersname (state) {
    return state.step3currentaddressmanagersname
  },
  step3currentaddresstelephone (state) {
    return state.step3currentaddresstelephone
  },
  step3currentaddressaddress1 (state) {
    return state.step3currentaddressaddress1
  },
  step3currentaddresscity (state) {
    return state.step3currentaddresscity
  },
  step3currentaddressstate (state) {
    return state.step3currentaddressstate
  },
  step3currentaddresszip (state) {
    return state.step3currentaddresszip
  },
  step3currentaddressmoveindate (state) {
    return state.step3currentaddressmoveindate
  },
  step3currentaddressmoveindateyear (state) {
    return state.step3currentaddressmoveindateyear
  },
  step3currentaddressreasonforleaving (state) {
    return state.step3currentaddressreasonforleaving
  },
  step3currentaddressrentamount (state) {
    return state.step3currentaddressrentamount
  },
  step3currentaddresscurrentapartmentcheckedsameasapplicant (state) {
    return state.step3currentaddresscurrentapartmentcheckedsameasapplicant
  },
  step3checkednopreviousapartment (state) {
    return state.step3checkednopreviousapartment
  },
  step3checkednopreviousapartmentcoapplicant (state) {
    return state.step3checkednopreviousapartmentcoapplicant
  },
  step3currentaddresscurrentapartmentname2 (state) {
    return state.step3currentaddresscurrentapartmentname2
  },
  step3currentaddressmanagersname2 (state) {
    return state.step3currentaddressmanagersname2
  },
  step3currentaddresstelephone2 (state) {
    return state.step3currentaddresstelephone2
  },
  step3currentaddressaddress12 (state) {
    return state.step3currentaddressaddress12
  },
  step3currentaddresscity2 (state) {
    return state.step3currentaddresscity2
  },
  step3currentaddressstate2 (state) {
    return state.step3currentaddressstate2
  },
  step3currentaddresszip2 (state) {
    return state.step3currentaddresszip2
  },
  step3currentaddressmoveindate2 (state) {
    return state.step3currentaddressmoveindate2
  },
  step3currentaddressmoveindate2year (state) {
    return state.step3currentaddressmoveindate2year
  },
  step3currentaddressreasonforleaving2 (state) {
    return state.step3currentaddressreasonforleaving2
  },
  step3currentaddressrentamount2 (state) {
    return state.step3currentaddressrentamount2
  },
  step3previousaddressapartmentname (state) {
    return state.step3previousaddressapartmentname
  },
  step3previousaddressmanagersname (state) {
    return state.step3previousaddressmanagersname
  },
  step3previousaddresstelephone (state) {
    return state.step3previousaddresstelephone
  },
  step3previousaddressaddress1 (state) {
    return state.step3previousaddressaddress1
  },
  step3previousaddresscity (state) {
    return state.step3previousaddresscity
  },
  step3previousaddressstate (state) {
    return state.step3previousaddressstate
  },
  step3previousaddresszip (state) {
    return state.step3previousaddresszip
  },
  step3previousaddressrentamount (state) {
    return state.step3previousaddressrentamount
  },
  step3previousaddressmoveindate (state) {
    return state.step3previousaddressmoveindate
  },
  step3previousaddressmoveindateyear (state) {
    return state.step3previousaddressmoveindateyear
  },
  step3previousaddressmoveoutdate (state) {
    return state.step3previousaddressmoveoutdate
  },
  step3previousaddressmoveoutdateyear (state) {
    return state.step3previousaddressmoveoutdateyear
  },
  step3previousaddressreasonforleaving (state) {
    return state.step3previousaddressreasonforleaving
  },
  step3previousaddressapartmentname2 (state) {
    return state.step3previousaddressapartmentname2
  },
  step3previousaddressmanagersname2 (state) {
    return state.step3previousaddressmanagersname2
  },
  step3previousaddresstelephone2 (state) {
    return state.step3previousaddresstelephone2
  },
  step3previousaddressaddress12 (state) {
    return state.step3previousaddressaddress12
  },
  step3previousaddresscity2 (state) {
    return state.step3previousaddresscity2
  },
  step3previousaddressstate2 (state) {
    return state.step3previousaddressstate2
  },
  step3previousaddresszip2 (state) {
    return state.step3previousaddresszip2
  },
  step3previousaddressrentamount2 (state) {
    return state.step3previousaddressrentamount2
  },
  step3previousaddressmoveindate2 (state) {
    return state.step3previousaddressmoveindate2
  },
  step3previousaddressmoveindate2year (state) {
    return state.step3previousaddressmoveindate2year
  },
  step3previousaddressmoveoutdate2 (state) {
    return state.step3previousaddressmoveoutdate2
  },
  step3previousaddressmoveoutdate2year (state) {
    return state.step3previousaddressmoveoutdate2year
  },
  step3previousaddressreasonforleaving2 (state) {
    return state.step3previousaddressreasonforleaving2
  },
  step4notcurrentlyemployed (state) {
    return state.step4notcurrentlyemployed
  },
  step4notcurrentlyemployedcoapplicant (state) {
    return state.step4notcurrentlyemployedcoapplicant
  },
  step4currentemploymentcompanyname (state) {
    return state.step4currentemploymentcompanyname
  },
  step4currentemploymentcompanyname2 (state) {
    return state.step4currentemploymentcompanyname2
  },
  step4currentemploymentsupervisorsname (state) {
    return state.step4currentemploymentsupervisorsname
  },
  step4currentemploymentsupervisorsname2 (state) {
    return state.step4currentemploymentsupervisorsname2
  },
  step4currentemploymenttelephone (state) {
    return state.step4currentemploymenttelephone
  },
  step4currentemploymenttelephone2 (state) {
    return state.step4currentemploymenttelephone2
  },
  step4currentemploymentaddress1 (state) {
    return state.step4currentemploymentaddress1
  },
  step4currentemploymentaddress12 (state) {
    return state.step4currentemploymentaddress12
  },
  step4currentemploymentcity (state) {
    return state.step4currentemploymentcity
  },
  step4currentemploymentcity2 (state) {
    return state.step4currentemploymentcity2
  },
  step4currentemploymentstate (state) {
    return state.step4currentemploymentstate
  },
  step4currentemploymentstate2 (state) {
    return state.step4currentemploymentstate2
  },
  step4currentemploymentzip (state) {
    return state.step4currentemploymentzip
  },
  step4currentemploymentzip2 (state) {
    return state.step4currentemploymentzip2
  },
  step4currentemploymentsalary (state) {
    return state.step4currentemploymentsalary
  },
  step4currentemploymentsalary2 (state) {
    return state.step4currentemploymentsalary2
  },
  step4currentemploymenthoursperweek (state) {
    return state.step4currentemploymenthoursperweek
  },
  step4currentemploymenthoursperweek2 (state) {
    return state.step4currentemploymenthoursperweek2
  },
  step4currentemploymentstartdate (state) {
    return state.step4currentemploymentstartdate
  },
  step4currentemploymentstartdateyear (state) {
    return state.step4currentemploymentstartdateyear
  },
  step4currentemploymentstartdate2 (state) {
    return state.step4currentemploymentstartdate2
  },
  step4currentemploymentstartdate2year (state) {
    return state.step4currentemploymentstartdate2year
  },
  step4currentemploymentenddate (state) {
    return state.step4currentemploymentenddate
  },
  step4currentemploymentenddateyear (state) {
    return state.step4currentemploymentenddateyear
  },
  step4currentemploymentenddate2 (state) {
    return state.step4currentemploymentenddate2
  },
  step4currentemploymentenddate2year (state) {
    return state.step4currentemploymentenddate2year
  },
  step4currentemploymentreasonforleaving (state) {
    return state.step4currentemploymentreasonforleaving
  },
  step4currentemploymentreasonforleaving2 (state) {
    return state.step4currentemploymentreasonforleaving2
  },
  step4additionalincomeincomesource (state) {
    return state.step4additionalincomeincomesource
  },
  step4additionalincomeincomesource2 (state) {
    return state.step4additionalincomeincomesource2
  },
  step4additionalincomeamount (state) {
    return state.step4additionalincomeamount
  },
  step4additionalincomeamount2 (state) {
    return state.step4additionalincomeamount2
  },
  step4additionalincometelephone (state) {
    return state.step4additionalincometelephone
  },
  step4additionalincometelephone2 (state) {
    return state.step4additionalincometelephone2
  },
  step4additionalincomeverifywith (state) {
    return state.step4additionalincomeverifywith
  },
  step4additionalincomeverifywith2 (state) {
    return state.step4additionalincomeverifywith2
  },
  step5name1 (state) {
    return state.step5name1
  },
  step5name12 (state) {
    return state.step5name12
  },
  step5telephone1 (state) {
    return state.step5telephone1
  },
  step5telephone12 (state) {
    return state.step5telephone12
  },
  step5relationship1 (state) {
    return state.step5relationship1
  },
  step5relationship12 (state) {
    return state.step5relationship12
  },
  step6pet1type (state) {
    return state.step6pet1type
  },
  step6pet2type (state) {
    return state.step6pet2type
  },
  step6pet3type (state) {
    return state.step6pet3type
  },
  step6pet1breed (state) {
    return state.step6pet1breed
  },
  step6pet2breed (state) {
    return state.step6pet2breed
  },
  step6pet3breed (state) {
    return state.step6pet3breed
  },
  step6pet1weight (state) {
    return state.step6pet1weight
  },
  step6pet2weight (state) {
    return state.step6pet2weight
  },
  step6pet3weight (state) {
    return state.step6pet3weight
  },
  step6criminal1misdemeanors (state) {
    return state.step6criminal1misdemeanors
  },
  step6criminal2evictions (state) {
    return state.step6criminal2evictions
  },
  step6agreements1 (state) {
    return state.step6agreements1
  },
  step6agreements2 (state) {
    return state.step6agreements2
  },
  step6agreements3 (state) {
    return state.step6agreements3
  },
  step6agreements4 (state) {
    return state.step6agreements4
  },
  step7firstname (state) {
    return state.step7firstname
  },
  step7lastname (state) {
    return state.step7lastname
  },
  step7nameshownoncard (state) {
    return state.step7nameshownoncard
  },
  step7emailaddress (state) {
    return state.step7emailaddress
  },
  step7address (state) {
    return state.step7address
  },
  step7city (state) {
    return state.step7city
  },
  step7state (state) {
    return state.step7state
  },
  step7zip (state) {
    return state.step7zip
  },
  step7creditcardtype (state) {
    return state.step7creditcardtype
  },
  step7creditcardnumber (state) {
    return state.step7creditcardnumber
  },
  step7expirationdatecreditcard (state) {
    return state.step7expirationdatecreditcard
  },
  step7expirationdatecreditcardyear (state) {
    return state.step7expirationdatecreditcardyear
  },
  step7cvi (state) {
    return state.step7cvi
  },
  usa_states () {
    return state.usa_states
  }
}
export default {
  state,
  mutations,
  actions,
  getters
}
