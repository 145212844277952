<template>
  <div>
    <div class="alert alert-danger">
      {{$t('Step1ChargesA', {lng: this.$store.getters.language}) }}
      ${{formatPrice(adultapplicationfee)}}
      {{$t('Step1ChargesB', {lng: this.$store.getters.language}) }}
      <!-- coapplicant-->
      <span v-if="this.$store.getters.docoapp === 'true'">
                    <br>
                    ${{formatPrice(parseFloat(adultapplicationfee))}}
                    {{$t('Step1ChargesC', {lng: this.$store.getters.language}) }}
                  </span>
      <span v-if="this.$store.getters.dorental === 'true'">
                    <br>Rental Report fee: ${{formatPrice(parseFloat(rentalfee))}}
                  </span>
      <span v-if="this.$store.getters.doevictions === 'true'">
                    <br>Eviction Report fee: ${{formatPrice(parseFloat(evictionfee))}}
                  </span>
      <span v-if="this.$store.getters.doemployment === 'true'">
                    <br>Employment Report fee: ${{formatPrice(parseFloat(employmentfee))}}
                  </span>
      <br>{{$t('Step1ChargesD', {lng: this.$store.getters.language}) }}
      <br>Total charge: ${{formatPrice(parseFloat(this.$store.getters.getTotalCharge))}}
    </div>
  </div>
</template>

<style scoped>
  .danger {
    border: 1px solid red;
  }
</style>

<script>

  export default {
    data () {
      return {
      }
    },
    computed: {
      rentalfee () {
        if (this.$store.getters.dorental) {
          if (this.$store.getters.docoapp === 'true') {
            return this.$store.getters.rentalreportprice * 2
          } else {
            return this.$store.getters.rentalreportprice
          }
        } else {
          return 0.00
        }
      },
      evictionfee () {
        if (this.$store.getters.doevictions === 'true') {
          if (this.$store.getters.docoapp === 'true') {
            return this.$store.getters.evictionprice * 2
          } else {
            return this.$store.getters.evictionprice
          }
        } else {
          return 0.00
        }
      },
      employmentfee () {
        if (this.$store.getters.doemployment === 'true') {
          if (this.$store.getters.docoapp === 'true') {
            return this.$store.getters.employmentreportprice * 2
          } else {
            return this.$store.getters.employmentreportprice
          }
        } else {
          return 0.00
        }
      },
      adultapplicationfee () {
        return Number(this.$store.getters.adultapplicationfee)
      }
    },
    methods: {
      formatPrice (value) {
        let val = (value / 1).toFixed(2).replace('.', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    }
  }
</script>
